<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.container-card {
  max-height: 350px;
}
</style>
<template>
  <b-card-group class="container-card main-margins mx-auto w-100 d-flex justify-content-between" deck>
    <Card
      :route="{ name: 'CreativeSets' }"
      service-class="projectManagement"
      service-name="Project Management"
      description="Create, edit and manage the Creative Sets. Find the creatives backups and validation pages."
    />

    <Card
      :route="{ name: 'LiveEditPreselect' }"
      service-class="creativeLibrary"
      service-name="Creative Library"
      description="Visualize and edit the creations. Share the result for validation."
    />

    <Card
      :route="{ name: 'DecisionTree' }"
      service-class="decisionTree"
      service-name="Decision Tree"
      description="Visualize and edit the decision tree. Pick the messages for every strategy."
    />
  </b-card-group>
</template>

<script>
import Card from "@/views/Home/Card.vue";

export default {
  name: "Home",
  title: "Home - Disneyland CMP",

  components: {
    Card,
  },
};
</script>
