import axios from "axios";
import BackEndpoints from "@/router/endpoints/BackEndpoints";
import User from "@/domain/User";
import ToastService from "@/services/ToastService";
import ErrorUtils from "@/utils/ErrorUtils";

export default {
  async getCurrentUser() {
    try {
      let response = await axios.get(BackEndpoints.currentUser());
      return new User(response.data);
    } catch (error) {
      ToastService.error(
        "Error when loading user",
        ErrorUtils.getMessage(error)
      );
      throw error;
    }
  },
  async oAuthAccessToken(){
    try {
      let resp = await axios.post(BackEndpoints.oAuthAccessToken());
      ToastService.success(
        "Success",
        "Refreshed google permissions successfully."
      );
      return { oAuthAccessToken: resp.data.oAuthAccessToken };
    } catch (error) {
      ToastService.error(
        "Error when refreshing google permissions",
        ErrorUtils.getMessage(error)
      );
      throw error;
    }
  },
  async updateAuthUsers() {
    try {
      await axios.post(BackEndpoints.updateAuthUsers());
      ToastService.success("Success", "User list has been updated.");
    } catch (error) {
      ToastService.error(
        "Error when updateAuthUsers",
        ErrorUtils.getMessage(error)
      );
      throw error;
    }
  }
}
