<style lang="scss">
$header-height: clamp(85px, 10%, 100px);

#app {
  height: 100vh;
}

.header {
  height: $header-height;
  position: relative;
}

.header::after {
  content: "";
  position: absolute;
  top: 0;
  width: 75%;
  height: calc($header-height + 40px);
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: inherit;
}
.header::before {
  content: "";
  top: 0;
  width: 65vw;
  height: calc($header-height + 40px);
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: inherit;
  box-shadow: 0 10px 10px rgb(0, 0, 0, 0.4);
  position: absolute;
}

.navbar {
  z-index: 5;
}

.navbar .dropdown {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .btn {
    padding: 0;
    margin: 0;
    border: 0;
    margin-right: 0px !important;
    border-radius: 50%;
    border: 3px solid white;
    height: 100%;
    padding-top: 2px;

    img {
      width: 70%;
    }

    &::after {
      display: none;
    }
  }
  ul {
    margin-top: 2rem;
  }
}

.empty-space {
  width: 66px;
}

.btn-home {
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 70%;
  }
}
</style>

<template>
  <div id="app">
    <header class="header" v-if="hasHeader" :style="{ 'background-color': getBgColor() }" :color="getBgColor()">
      <b-navbar
        class="h-100 d-flex"
        :class="$route.meta.external ? 'justify-content-center' : 'justify-content-between'"
      >
        <div class="btn-home" @click="$router.push(homeLink)" v-if="!$route.meta.external">
          <img src="./assets/images/pictos/home.svg" />
        </div>
        <breadcrumb
          :service-name="$route.meta.serviceName"
          :sub-service-name="subServiceName"
          :class="this.$route.name !== 'Home' ? 'set-separators' : 'underline'"
          class="pt-3 mt-3"
        />
        <b-dropdown variant="link" toggle-class="text-decoration-none icon" right v-if="!$route.meta.external">
          <template #button-content>
            <img class="m-auto" src="./assets/images/pictos/mickey.svg" />
          </template>

          <b-dropdown-item disabled>{{ user.email }}</b-dropdown-item>
          <b-dropdown-item v-if="user.isAdmin" @click="updateAuthUsers">Update auth users</b-dropdown-item>
          <b-dropdown-item v-if="user.isAdmin" @click="updateMessages">Update all messages</b-dropdown-item>
          <b-dropdown-item @click="logout">Logout</b-dropdown-item>
        </b-dropdown>
      </b-navbar>
    </header>

    <main :class="$route.meta.fullMain && 'full-main-height d-flex align-items-center'">
      <loading-overlay class="h-100" />
      <router-view
        class="h-100"
        v-if="user.id || external"
        :user="user"
        @update-sub-service-name="updateSubServiceName"
      />
    </main>
    <footer-logo v-if="hasLogo" />
  </div>
</template>
<script>
import User from "@/domain/User";
import BackEndpoints from "@/router/endpoints/BackEndpoints";
import FrontEndpoints from "@/router/endpoints/FrontEndpoints";
import AuthService from "@/services/AuthService";
import ToastService from "@/services/ToastService";
import UserService from "@/services/UserService";
import ErrorUtils from "@/utils/ErrorUtils";
import Breadcrumb from "@/views/layout/Breadcrumb.vue";
import FooterLogo from "@/views/layout/FooterLogo.vue";
import LoadingOverlay from "@/views/layout/LoadingOverlay";
import axios from "axios";

export default {
  name: "DisneylandCmp",
  data() {
    return {
      oAuthRefreshTimeout: null,
      user: User.empty(),
      subServiceName: "",
    };
  },
  computed: {
    hasHeader() {
      return this.$route.meta.header;
    },
    external() {
      return this.$route.meta.external;
    },
    hasLogo() {
      return this.$route.meta.displayLogo;
    },
    homeLink() {
      return FrontEndpoints.home;
    },
  },
  components: {
    LoadingOverlay,
    Breadcrumb,
    FooterLogo,
  },
  methods: {
    updateSubServiceName(subServiceName) {
      console.log("decision sendToParent", subServiceName);
      this.subServiceName = subServiceName;
    },
    async loadUser() {
      this.user = await UserService.getCurrentUser().catch(() => {
        AuthService.logout();
      });
    },
    async updateAuthUsers() {
      await UserService.updateAuthUsers();
    },
    async updateMessages() {
      try {
        const query = await axios.get(BackEndpoints.updateMessages);
        ToastService.success("Success", "Messages has been updated.");

        if (query.data.length > 0) {
          const errors = query.data;

          errors.forEach((error) => {
            ToastService.error(
              "Error when updating messages",
              `The spreadsheetUrl from creative set ${error.name} is not valid`
            );
          });
        }
      } catch (error) {
        ToastService.error("Error when updating messages", ErrorUtils.getMessage(error));
        throw error;
      }
    },
    logout() {
      AuthService.logout();
    },
    getBgColor() {
      switch (this.$route.meta.serviceName) {
        case "Content Management Platform":
          return "#122E5C";

        case "Project Management":
          return "#393E8F";

        case "Creative Library":
          return "#69CA6D";

        case "Decision Tree":
          return "#EA756E";
      }
    },
  },
  watch: {
    $route: {
      async handler() {
        this.subServiceName = this.$route.meta.subServiceName;

        if (this.$route.name !== "Login" && !this.$route.meta.external) {
          await this.loadUser();
          console.log("after load user", this.user);
          if (this.user?.id) {
            const FIFTY_HEIGHT_MINUTES = 58 * 60 * 1000;
            let date = this.user.oAuthTokenCreatedAt;
            let afterDate = new Date(date.getTime() + FIFTY_HEIGHT_MINUTES);
            let diff = afterDate - date;
            console.log("date", `${date}, ${afterDate}, ${diff}`);
            if (new Date() > afterDate) {
              console.log("Refresh token");
              await UserService.oAuthAccessToken();
              await this.loadUser();
            } else {
              clearTimeout(this.oAuthRefreshTimeout);
              this.oAuthRefreshTimeout = setTimeout(async () => {
                console.log("Refresh token after timeout");
                await UserService.oAuthAccessToken();
                await this.loadUser();
              }, diff);
            }
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.subServiceName = this.$route.meta.subServiceName;
  },
  async created() {
    this.$log.info("Application created");
  },
};
</script>
