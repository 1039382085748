<style scoped>
.limiter {
  overflow: hidden;

  background-image: url("/images/fondHomePage.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh !important;
}

.login100-more {
  background-color: rgba(45, 128, 171, 0.6);
  color: white;
}

</style>

<template>
  <div class="limiter pl-5 h-100 d-flex flex-column align-items-start justify-content-center">
    <div id="loginContainer" class="w-50 d-flex justify-content-center flex-column align-items-center">
      <h1 class="login100-form-title pb-2">ADventori CMPs</h1>
      <div class="w-100 login100-more p-1">
        Bienvenue sur la Disneyland Content Management Platform.
        <br/>
        <br/>

        Sur cet outil, vous pourrez :
        <br/>

        <br/>

        1/ Gestion de projet
        <br/>

        Créer et éditer vos campagnes, suivre leur état et télécharger vos
        backups.
        <br/>
        <br/>

        2/ Banner-validation
        <br/>

        Visualiser l'ensemble des messages et leur déclinaisons, sur tous les
        formats et langues et modifier leurs contenus.
        <br/>
        <br/>

        3/ Gestion des scénarios
        <br/>

        Visualiser les différentes audiences et leviers, et choisir les messages à adresser à chacun.
        <br/>
        <br/>

        Google User Data
        <br/>

        Les informations des profils Google sont utilisées afin de vérifier l'autorisation d'accès à l'application, mais
        ne sont pas stockées.
        Les droits d'écriture aux Google Spreadsheets sont uniquement utilisés pour écrire sur des Google Spreadsheets
        dont ADventori est le propriétaire.
        <br/>
        <br/>
        <a href="/pdf/2019-01-01-CGV-Adventori.pdf">Conditions générale de vente</a>
        <br/>

        <a href="/pdf/Platform%20Privacy%20Policy%20_%20ADventori.pdf">Politique de confidentialité</a>

      </div>
      <b-button block variant="success" class="mt-2" :to="{ name: 'Login' }">
        Disneyland Paris CMP >
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  title: "HomePage - Disneyland CMP",
  data() {
    return {};
  },
};
</script>
