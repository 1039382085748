<style scoped lang="scss">
.breadcrumb {
  all: unset;
  border-radius: inherit;
  width: 60vw;
  &_serviceName {
    width: initial;
    &_h1 {
      font-size: 2.5vw;
      text-align: center;
    }
  }

  &_subServiceName {
    font-size: 1.5vw;
    text-transform: capitalize;
  }

  &.set-separators {
    .breadcrumb_serviceName {
      position: relative;
      max-width: 30vw;
      display: block;

      &::before {
        content: "";
        background-image: url(../../assets/images/pictos/separator.svg);
        height: 7px;
        width: 95%;
        background-repeat: no-repeat;
        left: -100%;
        display: block;
        position: absolute;
        background-position: right;
      }

      &::after {
        content: "";
        background-image: url(../../assets/images/pictos/separator.svg);
        transform: rotate(180deg);
        height: 7px;
        width: 95%;
        background-repeat: no-repeat;
        right: -100%;
        display: block;
        position: absolute;
        background-position: right;
      }
    }
  }

  &.underline {
    .breadcrumb_serviceName {
      display: flex;
      flex-direction: column;
      &::after {
        content: "";
        background-image: url(../../assets/images/pictos/underline.svg);
        height: 8px;
        width: 90%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% auto;
        text-align: center;
      }
    }
  }
}
</style>
<template>
  <div
    class="breadcrumb d-flex flex-column justify-content-center align-items-center h-100 text-white"
  >
    <div
      class="breadcrumb_serviceName d-flex align-items-center justify-content-center text-center"
    >
      <h1 class="breadcrumb_serviceName_h1 px-3">{{ serviceName }}</h1>
    </div>
    <h2 class="breadcrumb_subServiceName" v-if="subServiceName">
      {{ convertText }}
    </h2>
  </div>
</template>
<script>
export default {
  name: "Breadcrumb",
  props: {
    serviceName: {
      type: String,
      required: true,
    },
    subServiceName: {
      type: String,
      required: false,
    },
  },
  computed: {
    convertText() {
      const result = this.subServiceName.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

      return finalResult;
    },
  },
};
</script>
