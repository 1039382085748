<style scoped lang="scss">
#open {
  bottom: 3rem;
  right: 3rem;
}

.main-size-100 {
  height: calc(100vh - 70px - 80px) !important;
}
</style>

<template>
  <div class="main-size-100 margin-top-content">
    <b-btn
      id="open"
      class="position-fixed rounded-circle border-0 p-2"
      style="background-color: #0ca788"
      :href="spreadsheetLink"
    >
      <img height="50px" width="50px" src="../assets/images/open_icon.png" />
    </b-btn>
    <iframe
      class="w-100 h-100"
      :src="
        'https://docs.google.com/spreadsheets/d/' +
        spreadsheetId +
        '/edit?rm=minimal'
      "
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "BulkEdit",
  title: "Creative Library - Disneyland CMP",
  computed: {
    spreadsheetId() {
      return this.$route.params.spreadsheetId;
    },
    spreadsheetLink() {
      return `https://docs.google.com/spreadsheets/d/${this.spreadsheetId}/edit#gid=0`;
    },
  },
};
</script>
