<template>
  <b-overlay :show="show" no-wrap no-fade opacity="1"></b-overlay>
</template>

<script>
import LoadingService from "@/services/LoadingService";

export default {
  name: "LoadingOverlay",
  data() {
    return {
      show: false,
    }
  },
  created() {
    LoadingService.onStateChange((state) => {
      this.show = state;
    });
  }
}
</script>

<style scoped>

</style>