<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.breadcrump {
  border-radius: 200px !important;
}

.empty {
  width: 2rem;
  height: 2rem;
}

.close {
  width: 2rem;
  height: 2rem;
  -webkit-mask-size: 50%;
  mask-size: 50%;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
  cursor: pointer;
  -webkit-mask-image: url(../../assets/images/pictos/cross.svg);
  mask-image: url(../../assets/images/pictos/cross.svg);
  background-color: $primary;
  opacity: 1;
}

.close:hover {
  background-color: $decision-tree;
}

input::placeholder {
  color: gray;
}
</style>
<template>
  <div class="w-75 bg-white border border-dark rounded mb-4 mx-auto">
    <b-row class="justify-content-between align-items-center p-2 m-0 w-100">
      <span class="empty"></span>
      <h4 class="text-white breadcrump bg-primary px-4 py-2 mt-3">Default</h4>
      <div @click="closeEdit" class="close my-auto"></div>
    </b-row>
    <b-card-group class="d-flex flex-column rounded">
      <b-card
        class="m-3 border border-dark rounded bg-future border-ready">
        <b-row class="m-0 p-0 mb-2">
          <b-col class="p-0 pr-1">
            <b-form-select
              class="selector d-flex flex-column mw-25 mx-auto"
              v-model="selected.Creative"
              :options="creativeSets"
              @change="changeCreativeSet"
            ></b-form-select>
          </b-col>
          <b-col class="p-0 pl-1">
            <b-form-select
              class="selector d-flex flex-column mw-25 mx-auto"
              :options="messages[selected.Creative]"
              v-model="selected.Message"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="m-0 p-0 w-100">
          <b-form-input v-model="selected.Redirection"></b-form-input>
        </b-row>
      </b-card>
      <b-button class="text-white bg-ready border-0 w-25 mb-3 mx-auto mb-4" @click="save" v-if="!isLoading">Save
      </b-button>
      <b-spinner v-else class="mb-3 mx-auto"></b-spinner>
    </b-card-group>
  </div>
</template>

<script>
import ToastService from "@/services/ToastService";
import Email from "@/domain/Email";

export default {
  name: "EditDefaultOffer",
  data() {
    return {
      selectArray: {},
      oldArray: {},
      selected: [],
      newDates: {},
      email: Email.empty(),
    };
  },
  props: {
    dataProps: {type: Object, required: true},
    creativeSets: {type: Array, required: true},
    messages: {type: Object, required: true},
    isLoading: {type: Boolean, required: true},
    strategy: {type: String, required: true},
  },
  methods: {
    closeEdit() {
      this.$emit("close-edit");
    },
    changeCreativeSet() {
      if(this.selected.Creative === "Empty"){
        this.selected.Message = "Empty";
      }
    },
    async save() {
      let canSubmit = true;

      if (this.selected.Creative === 'Empty' || this.selected.Message === 'Empty') {
        canSubmit = false;
        ToastService.error("Error", "You have to choose a Creative Set and a Message");
      }
      if (!this.selected.Creative && !this.selected.Message && !this.selected.Redirection) {
        canSubmit = false;
        ToastService.error("Error", "An input is empty");
      }

      if (canSubmit) {
        this.email.body = Email.empty().body.replace(
          "[STRATEGY]",
          this.strategy
        ).replace(
          "[LANG]",
          this.$route.params.market
        );

        this.email.subject = Email.empty().subject.replace(
          "[STRATEGY]",
          this.strategy
        ).replace(
          "[LANG]",
          this.$route.params.market
        );

        this.$emit("submit", [this.selected], this.email);
      }
    },
  },
  beforeMount() {
    // eslint-disable-next-line no-undef
    this.selected = this.dataProps;
  },
};
</script>
