export default {
  vm: null,
  init(vm) {
    this.vm = vm;
  },
  formatMessage(message) {
    if (Array.isArray(message)) {
      return message.flatMap(messageEl => [
        messageEl,
        this.vm.$createElement('br'),
      ]);
    } else {
      return message;
    }
  },
  toast(title, message, options) {
    this.vm.$bvToast.toast(this.formatMessage(message), {
      title: title,
      ...options,
    });
  },
  info(title, message, options) {
    this.vm.$log.info(title, message);
    this.toast(title, message, {
      variant: "info",
      ...options,
    });
  },
  success(title, message, options) {
    this.vm.$log.info(title, message);
    this.toast(title, message, {
      variant: "success",
      ...options,
    });
  },
  warning(title, message, options) {
    this.vm.$log.warn(title, message);
    this.toast(title, message, {
      variant: "warning",
      ...options,
    });
  },
  error(title, message, options) {
    this.vm.$log.error(title, message);
    this.toast(title, message, {
      variant: "danger",
      noAutoHide: true,
      ...options,
    });
  },
};
