<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.breadcrump {
  border-radius: 200px !important;
}

.bg-passed {
  background: $gray-200;
  pointer-events: none;
  opacity: 0.75;
}

.bg-live {
  background-color: rgb(105, 202, 109, 0.2);
}

.bg-comingNext {
  background-color: white;
}

.empty {
  width: 2rem;
  height: 2rem;
}

.close {
  width: 2rem;
  height: 2rem;
  -webkit-mask-size: 50%;
  mask-size: 50%;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
  cursor: pointer;
  -webkit-mask-image: url(../../assets/images/pictos/cross.svg);
  mask-image: url(../../assets/images/pictos/cross.svg);
  background-color: $primary;
  opacity: 1;
}

.close:hover {
  background-color: $decision-tree;
}

.date-picker {
  width: fit-content;
}

input::placeholder {
  color: gray;
}

.no-wrap {
  flex-wrap: nowrap;
}

.border-position {
  margin: 0.75rem auto 1.5rem auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
<template>
  <div class="w-75 bg-white border border-dark rounded mb-4 mx-auto">
    <b-row class="justify-content-between align-items-center p-2 m-0 w-100">
      <span class="empty"></span>
      <h4 class="text-white breadcrump bg-primary px-4 py-2 mt-3">{{ messagesByOffer[0] }}</h4>
      <div @click="closeEdit" class="close my-auto"></div>
    </b-row>
    <b-card-group class="d-flex flex-column rounded">
      <div v-for="period in messagesByPeriod" :key="period[0]">
        <div v-if="period[0] === 'passed' && Object.keys(period[1]).length > 0" class="w-25 border-top border-gray border-position"></div>
        <b-row class="m-3 align-items-center no-wrap" v-for="offer in Object.entries(period[1])" :key="offer[0]">
          <b-card class="border border-dark rounded w-100" :class="'bg-' + period[0]">
            <b-row class="m-0 p-0 gap-1">
              <div style="width: 155px">
                <b-row class="m-0">
                  <b-form-datepicker
                    :id="`${offer[0]}_startDate_${period[0]}`"
                    v-model="selectedDates[offer[0]].startDate"
                    class="mb-2 date-picker align-items-center justify-content-center w-100"
                    size="m"
                    locale="fr"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    @input="setStartDate(period[0], offer[0], selectedDates[offer[0]].startDate)"
                  ></b-form-datepicker>
                </b-row>
                <b-row class="m-0">
                  <b-form-datepicker
                    :id="`${offer[0]}_endDate_${period[0]}`"
                    v-model="selectedDates[offer[0]].endDate"
                    :min="selectedDates[offer[0]].startDate"
                    size="m"
                    locale="fr"
                    class="date-picker align-items-center w-100"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    @input="setEndDate(period[0], offer[0], selectedDates[offer[0]].endDate)"
                  ></b-form-datepicker>
                </b-row>
                <b-row class="m-0 mt-4">
                  <input
                    type="checkbox"
                    :id="`${offer[0]}_shareOfVoice_${period[0]}`"
                    :name="`${offer[0]}_shareOfVoice_${period[0]}`"
                    variant="primary"
                    class="bg-primary"
                    :checked="shareOfVoiceDisplayed[offer[0]]"
                    @change="toggleShareOfVoice(offer[0], period[0])"
                  />
                  <label :for="`${offer[0]}_shareOfVoice_${period[0]}`" class="m-0 pl-2">Share of voice</label>
                </b-row>
              </div>
              <div style="flex-grow: 1">
                <div
                  class="d-flex m-0 p-0 align-items-center"
                  :class="index !== offer[1].length - 1 ? 'mb-4' : 'mb-2'"
                  v-for="(message, index) in offer[1]"
                  :key="index"
                >
                  <b-col :sm="offer[1].length > 1 ? 11 : 12" class="m-0 p-0">
                    <b-row class="m-0 p-0 mb-2">
                      <b-col sm="2" class="pl-0" v-if="shareOfVoiceDisplayed[offer[0]]">
                        <b-row class="m-0 p-0 d-flex flex-row align-items-center" style="flex-wrap: nowrap">
                          <b-form-input
                            type="number"
                            class="text-center p-0"
                            v-model="displayedMessages[message['#id']].ShareOfVoice"
                            :state="displayedMessages[message['#id']].IsShareValid"
                            @input="changeShare(offer[0], message['#id'])"
                          ></b-form-input>
                          <div class="ml-1">%</div>
                        </b-row>
                      </b-col>
                      <b-col class="p-0 pr-1">
                        <b-form-select
                          :id="`${offer[0]}_Creative_${period[0]}`"
                          class="selector d-flex flex-column mw-25 mx-auto"
                          :options="creativeSets"
                          v-model="displayedMessages[message['#id']].Creative"
                          :state="displayedMessages[message['#id']].IsCreativeValid"
                          @change="changeCreative(message['#id'])"
                        ></b-form-select>
                        <b-form-invalid-feedback id="input-live-feedback">
                          This Creative is not valid.
                        </b-form-invalid-feedback>
                      </b-col>
                      <b-col class="p-0 pl-1">
                        <b-form-select
                          :id="`${offer[0]}_Message_${period[0]}`"
                          class="selector d-flex flex-column mw-25 mx-auto"
                          :options="messages[displayedMessages[message['#id']].Creative]"
                          v-model="displayedMessages[message['#id']].Message"
                          :state="displayedMessages[message['#id']].IsMessageValid"
                          @change="changeMessage(message['#id'])"
                        ></b-form-select>
                        <b-form-invalid-feedback id="input-live-feedback">
                          This Message is not valid.
                        </b-form-invalid-feedback>
                      </b-col>
                    </b-row>
                    <b-row class="m-0 p-0">
                      <b-form-input
                        id="input-live"
                        v-model="displayedMessages[message['#id']].Redirection"
                        :state="displayedMessages[message['#id']].IsUrlRedirectionValid"
                        aria-describedby="input-live-help input-live-feedback"
                        placeholder="Enter a valid URL"
                        trim
                        type="url"
                        @change="isUrl(message['#id'])"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-live-feedback">
                        This url is not valid.
                      </b-form-invalid-feedback>
                    </b-row>
                  </b-col>
                  <b-col v-if="offer[1].length > 1" sm="1" class="ml-2 p-0">
                    <div @click="removeOffer(period[0], offer[0], message['#id'])" class="close bg-decisionTree"></div>
                  </b-col>
                </div>
                <div v-if="shareOfVoiceDisplayed[offer[0]]" :class="shareOfVoicesSum[offer[0]] !== 100 ? 'text-danger' : ''">
                  Total : {{ shareOfVoicesSum[offer[0]] }}%
                </div>
                <b-row v-if="shareOfVoiceDisplayed[offer[0]]" class="m-0 p-0 w-100 d-flex justify-content-end">
                  <b-button variant="primary" class="text-white" @click="addMessage(period[0], offer[0])">
                    Add message
                  </b-button>
                </b-row>
              </div>
            </b-row>
          </b-card>
          <div @click="removeOffer(period[0], offer[0])" class="close ml-2 bg-decisionTree"></div>
        </b-row>
      </div>
      <b-button variant="decisionTree" class="text-white w-25 mb-3 mx-auto mt-4" @click="addNewFlight">
        Add new flight
      </b-button>
      <b-button class="text-white bg-ready border-0 w-25 mb-3 mx-auto mb-4" @click="save" v-if="!isLoading">
        Save
      </b-button>
      <b-spinner v-else class="mb-3 mx-auto"></b-spinner>
    </b-card-group>
  </div>
</template>

<script>
import Email from "@/domain/Email";
import DecisionTreeService from "@/services/DecisionTreeService";

export default {
  name: "EditOffer",
  data() {
    return {
      displayedMessages: {},
      originalMessages: {},
      shareOfVoiceDisplayed: {},
      shareOfVoicesSum: {},
      messagesByOffer: [],
      selectedDates: {},
      email: Email.empty(),
      maxId: this.maxIdProps,
      rowsToDelete: [],
      dateLocale: "fr",
      emptyValue: "Empty"
    };
  },
  props: {
    dataProps: {type: Array, required: true},
    creativeSets: {type: Array, required: true},
    messages: {type: Object, required: true},
    isLoading: {type: Boolean, required: true},
    strategy: {type: String, required: true},
    maxIdProps: {type: Number, required: true},
  },
  computed: {
    messagesByPeriod() {
      return Object.entries(this.messagesByOffer[1]).sort(function (a, b) {
        const order = ["live", "comingNext", "passed", "newFlight"];
        return order.indexOf(a[0]) - order.indexOf(b[0]);
      });
    },
  },
  methods: {
    closeEdit() {
      this.$emit("close-edit");
    },

    splitDate(date) {
      const [getStartDate, getEndDate] = date.split(" > ");
      const startDate = DecisionTreeService.parseDate(getStartDate);
      const endDate = DecisionTreeService.parseDate(getEndDate);
      return {startDate, endDate};
    },

    changeCreative(id) {
      const message = this.displayedMessages[id];
      message["Message"] = this.emptyValue;
      message["IsMessageValid"] = null;
      message["IsCreativeValid"] = message["Creative"] && message["Creative"] !== this.emptyValue;

      this.displayedMessages = {...this.displayedMessages};
    },

    changeMessage(id) {
      const message = this.displayedMessages[id];
      message["IsMessageValid"] = message["Message"] && message["Message"] !== this.emptyValue;

      this.displayedMessages = {...this.displayedMessages};
    },

    toggleShareOfVoice(dates, category) {
      this.shareOfVoiceDisplayed[dates] = !this.shareOfVoiceDisplayed[dates];

      if (!this.shareOfVoiceDisplayed[dates]) {
        const messageToKeep = this.messagesByOffer[1][category][dates].shift();
        this.displayedMessages[messageToKeep["#id"]].ShareOfVoice = 100;
        this.displayedMessages[messageToKeep["#id"]].IsShareValid = null;
        this.messagesByOffer[1][category][dates].forEach((message) => {
          if (Object.keys(this.originalMessages).some((id) => id === message["#id"])) {
            this.rowsToDelete.push(message);
          }
        });
        this.messagesByOffer[1][category][dates] = [messageToKeep];
        this.messagesByOffer = {...this.messagesByOffer};
      } else {
        this.updateShareOfVoiceSum(dates);
      }

      this.shareOfVoiceDisplayed = {...this.shareOfVoiceDisplayed};
    },

    addMessage(category, dates, isNewFlight = false) {
      this.maxId++;

      if (this.strategy === "affiliation") {
        if (isNewFlight) {
          this.messagesByOffer[1]["newFlight"][dates].push({
            "#Market": this.$route.params.market,
            "#MessageOffer": this.messagesByOffer[0],
            "#id": this.maxId,
            Creative: "",
            IsCreativeValid: null,
            EndDate: this.splitDate(dates).endDate,
            Message: "",
            IsMessageValid: null,
            MessageOffer: this.messagesByOffer[0],
            Redirection: "",
            IsUrlRedirectionValid: null,
            ShareOfVoice: "0",
            IsShareValid: null,
            StartDate: this.splitDate(dates).startDate,
            Statut: "active",
          });
        } else {
          this.messagesByOffer[1][category][dates].push({
            "#Market": this.$route.params.market,
            "#MessageOffer": this.messagesByOffer[0],
            "#id": this.maxId,
            Creative: "",
            IsCreativeValid: null,
            EndDate: this.splitDate(dates).endDate,
            Message: "",
            IsMessageValid: null,
            MessageOffer: this.messagesByOffer[0],
            Redirection: "",
            IsUrlRedirectionValid: null,
            ShareOfVoice: "0",
            IsShareValid: null,
            StartDate: this.splitDate(dates).startDate,
            Statut: "active",
          });
        }

        this.displayedMessages[this.maxId] = {
          "#Market": this.$route.params.market,
          "#MessageOffer": this.messagesByOffer[0],
          "#id": this.maxId,
          Creative: "",
          IsCreativeValid: null,
          EndDate: this.splitDate(dates).endDate.toLocaleDateString("fr"),
          Message: "",
          IsMessageValid: null,
          MessageOffer: this.messagesByOffer[0],
          Redirection: "",
          IsUrlRedirectionValid: null,
          ShareOfVoice: "0",
          IsShareValid: null,
          StartDate: this.splitDate(dates).startDate.toLocaleDateString("fr"),
          Statut: "active",
        };
      } else if (this.strategy === "dataProvider") {
        if (isNewFlight) {
          this.messagesByOffer[1]["newFlight"][dates].push({
            "#id": this.maxId,
            Market: this.$route.params.market,
            MessageNumber: this.messagesByOffer[0],
            Statut: "active",
            ShareOfVoice: "0",
            IsShareValid: null,
            StartDate: this.splitDate(dates).startDate,
            EndDate: this.splitDate(dates).endDate,
            Creative: "",
            IsCreativeValid: null,
            Message: "",
            IsMessageValid: null,
            Redirection: "",
            IsUrlRedirectionValid: null,
          });
        } else {
          this.messagesByOffer[1][category][dates].push({
            "#id": this.maxId,
            Market: this.$route.params.market,
            MessageNumber: this.messagesByOffer[0],
            Statut: "active",
            ShareOfVoice: "0",
            IsShareValid: null,
            StartDate: this.splitDate(dates).startDate,
            EndDate: this.splitDate(dates).endDate,
            Creative: "",
            IsCreativeValid: null,
            Message: "",
            IsMessageValid: null,
            Redirection: "",
            IsUrlRedirectionValid: null,
          });
        }

        this.displayedMessages[this.maxId] = {
          "#id": this.maxId,
          Market: this.$route.params.market,
          MessageNumber: this.messagesByOffer[0],
          Statut: "active",
          ShareOfVoice: "0",
          IsShareValid: null,
          StartDate: this.splitDate(dates).startDate.toLocaleDateString("fr"),
          EndDate: this.splitDate(dates).endDate.toLocaleDateString("fr"),
          Creative: "",
          IsCreativeValid: null,
          Message: "",
          IsMessageValid: null,
          Redirection: "",
          IsUrlRedirectionValid: null
        };
      }

      this.messagesByOffer = {...this.messagesByOffer};
      this.displayedMessages = {...this.displayedMessages};
    },

    async save() {
      const diff = [];
      let isAllValid = true;
      for (const row in this.displayedMessages) {
        const message = this.displayedMessages[row];
        let isValid = true;

        // URL validation
        this.isUrl(row);
        if (!message.IsUrlRedirectionValid) {
          message.IsUrlRedirectionValid = false;
          isValid = false;
        }

        // creative validation
        if (!message.Creative || message.Creative === this.emptyValue) {
          message.IsCreativeValid = false;
          isValid = false;
        }

        // message validation
        if (!message.Message || message.Message === this.emptyValue) {
          message.IsMessageValid = false;
          isValid = false;
        }

        // share of voices validation
        if (!message.ShareOfVoice || message.ShareOfVoice === "") {
          message.IsShareValid = false;
          isValid = false;
        }

        // add to diff if valid and different
        if (isValid) {
          if (!this.originalMessages[row]) {
            diff.push(message);
          } else {
            if (!Object.values(message).every((v, i) => v === Object.values(this.originalMessages[row])[i])) {
              diff.push(message);
            }
          }
        } else {
          isAllValid = false;
        }
      }

      if (isAllValid) {
        this.email.body = Email
          .empty()
          .body
          .replace("[STRATEGY]", this.strategy)
          .replace("[LANG]", this.$route.params.market);

        this.email.subject = Email
          .empty()
          .subject
          .replace("[STRATEGY]", this.strategy)
          .replace("[LANG]", this.$route.params.market);

        this.$emit("submit", diff, this.rowsToDelete, this.email);
      }
    },

    isUrl(index) {
      const message = this.displayedMessages[index];
      let result;
      if (message.Redirection === null || message.Redirection === "") {
        result = null;
      } else {
        result = message.Redirection.match(/^https?:\/\/(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/\S*)?$/) != null;
      }
      message.IsUrlRedirectionValid = result;

      this.displayedMessages = { ...this.displayedMessages };
    },

    setStartDate(category, keyOldDates, newStartDate) {
      const newStartDateDate = new Date(newStartDate);
      const newStartDateStr = newStartDateDate.toLocaleDateString(this.dateLocale);

      const splitDates = this.splitDate(keyOldDates);
      const startDateStr = splitDates.startDate.toLocaleDateString(this.dateLocale);
      const endDateStr = splitDates.endDate.toLocaleDateString(this.dateLocale);
      const keyNewDates = `${newStartDateStr} > ${endDateStr}`;

      this.shareOfVoiceDisplayed[keyNewDates] = this.messagesByOffer[1][category][keyOldDates].length > 1;
      delete this.shareOfVoiceDisplayed[keyOldDates];

      this.messagesByOffer[1][category][keyOldDates].forEach((row) => {
        row.StartDate = newStartDateStr;
      });
      this.messagesByOffer[1][category][keyNewDates] = [...this.messagesByOffer[1][category][keyOldDates]];
      delete this.messagesByOffer[1][category][keyOldDates];

      this.selectedDates[keyOldDates].startDate = newStartDate;
      this.selectedDates[keyNewDates] = this.selectedDates[keyOldDates];
      delete this.selectedDates[keyOldDates];

      Object.entries(this.displayedMessages)
        .filter((row) => { return row[1].StartDate === startDateStr && row[1].EndDate === endDateStr; })
        .forEach((row) => {
          this.displayedMessages[row[0]] = {
            ...this.displayedMessages[row[0]],
            StartDate: newStartDateStr,
          };
        });

      this.messagesByOffer = {...this.messagesByOffer};
      this.displayedMessages = {...this.displayedMessages};

      if (newStartDateDate > splitDates.endDate) {
        // start date can't be after end date, so update end date
        this.setEndDate(category, keyNewDates, newStartDate);
      }
    },

    setEndDate(category, keyOldDates, newEndDate) {
      const splitDates = this.splitDate(keyOldDates);
      const startDateStr = splitDates.startDate.toLocaleDateString(this.dateLocale);
      const endDateStr = splitDates.endDate.toLocaleDateString(this.dateLocale);
      const newEndDateStr = new Date(newEndDate).toLocaleDateString(this.dateLocale);
      const keyNewDates = `${startDateStr} > ${newEndDateStr}`;

      this.shareOfVoiceDisplayed[keyNewDates] = this.messagesByOffer[1][category][keyOldDates].length > 1;
      delete this.shareOfVoiceDisplayed[keyOldDates];

      this.messagesByOffer[1][category][keyOldDates].forEach((row) => {
        row.EndDate = newEndDateStr;
      });
      this.messagesByOffer[1][category][keyNewDates] = [...this.messagesByOffer[1][category][keyOldDates]];
      delete this.messagesByOffer[1][category][keyOldDates];

      this.selectedDates[keyOldDates].endDate = newEndDate;
      this.selectedDates[keyNewDates] = this.selectedDates[keyOldDates];
      delete this.selectedDates[keyOldDates];

      Object.entries(this.displayedMessages)
        .filter((row) => { return row[1].StartDate === startDateStr && row[1].EndDate === endDateStr; })
        .forEach((row) => {
          this.displayedMessages[row[0]] = {
            ...this.displayedMessages[row[0]],
            EndDate: newEndDateStr,
          };
        });

      this.messagesByOffer = {...this.messagesByOffer};
      this.displayedMessages = {...this.displayedMessages};
    },

    addNewFlight() {
      const date = new Date().toLocaleDateString("fr");
      this.maxId++;

      if (!this.selectedDates[`${date} > ${date}`]) {
        this.selectedDates[`${date} > ${date}`] = {};
      }

      this.selectedDates[`${date} > ${date}`] = {
        startDate: new Date(),
        endDate: new Date(),
      };

      this.shareOfVoiceDisplayed[`${date} > ${date}`] = false;

      if (!this.messagesByOffer[1]["newFlight"][`${date} > ${date}`]) {
        this.messagesByOffer[1]["newFlight"][`${date} > ${date}`] = [];
      }

      const valuesAffiliation = {
        "#Market": this.$route.params.market,
        "#MessageOffer": this.messagesByOffer[0],
        "#id": this.maxId,
        MessageOffer: this.messagesByOffer[0],
        Statut: "active",
        ShareOfVoice: "100",
        IsShareValid: null,
        StartDate: date,
        EndDate: date,
        Creative: this.emptyValue,
        IsCreativeValid: null,
        Message: this.emptyValue,
        IsMessageValid: null,
        Redirection: "",
        IsUrlRedirectionValid: null,
      };

      const valuesDataProvider = {
        "#id": this.maxId,
        Market: this.$route.params.market,
        MessageNumber: this.messagesByOffer[0],
        Statut: "active",
        ShareOfVoice: "100",
        IsShareValid: null,
        StartDate: date,
        EndDate: date,
        Creative: this.emptyValue,
        IsCreativeValid: null,
        Message: this.emptyValue,
        IsMessageValid: null,
        Redirection: "",
        IsUrlRedirectionValid: null,
      };

      const values = {affiliation: valuesAffiliation, dataProvider: valuesDataProvider};

      this.messagesByOffer[1]["newFlight"][`${date} > ${date}`].push({...values[this.strategy]});

      this.displayedMessages[this.maxId] = {
        ...values[this.strategy],
      };

      this.shareOfVoiceDisplayed = {...this.shareOfVoiceDisplayed};
      this.messagesByOffer = {...this.messagesByOffer};
      this.displayedMessages = {...this.displayedMessages};
    },

    removeOffer(time, date, id = null) {
      if (!id) {
        this.messagesByOffer[1][time][date].forEach((message) => {
          const messageId = message["#id"];
          if (Object.keys(this.originalMessages).some((id) => id === messageId)) {
            this.rowsToDelete.push(message);
          }
          delete this.displayedMessages[messageId];
        });
        delete this.messagesByOffer[1][time][date];
      } else {
        if (Object.keys(this.originalMessages).some((id1) => id1 === id)) {
          this.rowsToDelete.push(this.messagesByOffer[1][time][date].find((message) => message["#id"] === id));
        }
        this.messagesByOffer[1][time][date] = this.messagesByOffer[1][time][date].filter((message) => message["#id"] !== id);
        delete this.displayedMessages[id];

        if (this.messagesByOffer[1][time][date].length === 0) {
          delete this.messagesByOffer[1][time][date];
          delete this.selectedDates[date];
        }
      }

      this.messagesByOffer = {...this.messagesByOffer};
      this.selectedDates = {...this.selectedDates};
      this.displayedMessages = {...this.displayedMessages};
    },

    getSumShareOfVoice(name, offer) {
      return offer
        .map((message) => {
          return message.ShareOfVoice;
        })
        .map(Number)
        .reduce((a, b) => {
          return a + b;
        });
    },

    getMessagesByDates(dates) {
      const [startDate, endDate] = dates.split(' > ');
      return Object.values(this.displayedMessages).filter((message) => {
        return message["StartDate"] === startDate && message["EndDate"] === endDate;
      });
    },

    updateShareOfVoiceSum(dates) {
      this.shareOfVoicesSum[dates] = this.getSumShareOfVoice(dates, this.getMessagesByDates(dates));
      this.shareOfVoicesSum = {...this.shareOfVoicesSum}
    },

    changeShare(dates, id) {
      const message = this.displayedMessages[id];
      message.IsShareValid = null;

      this.updateShareOfVoiceSum(dates);
    }
  },
  beforeMount() {
    // eslint-disable-next-line no-undef
    this.messagesByOffer = structuredClone(this.dataProps);

    Object.entries(this.messagesByOffer[1]).forEach((category) => {
      Object.entries(category[1]).forEach((dates) => {
        this.selectedDates[dates[0]] = {
          startDate: this.splitDate(dates[0]).startDate,
          endDate: this.splitDate(dates[0]).endDate,
        };
        this.shareOfVoiceDisplayed[dates[0]] = dates[1].length > 1;
        this.shareOfVoicesSum[dates[0]] = this.getSumShareOfVoice(dates[0], dates[1]);
        dates[1].forEach((message) => {
          this.displayedMessages[message["#id"]] = {...message};
          this.originalMessages[message["#id"]] = {...message};
        });
      });
    });
    this.messagesByOffer[1]["newFlight"] = {};
  },
};
</script>
