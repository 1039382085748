export default {
  getMessage(error) {
    return (
        error?.response?.data?._embedded?.errors?.flatMap(error => ['- ' + error?.message, ''])?.slice(0, -1) ||
        error?.result?.error?.message  ||
        error?.response?.data?.message ||
        error?.message
    );
  },
};
