export default class User {
  constructor(props) {
    this.id = props.id;
    this.email = props.email;
    this.firstName = props.firstName;
    this.lastName = props.lastName;
    this.isAdmin = props.isAdmin;
    this.oAuthAccessToken = props.oAuthAccessToken;
    this.oAuthRefreshToken = props.oAuthRefreshToken;
    this.oAuthTokenCreatedAt = new Date(props.oAuthTokenCreatedAt);
  }

  static empty() {
    return new User({
      id: null,
      email: null,
      firstName: null,
      lastName: null,
      isAdmin: false,
      oAuthTokenCreatedAt: null
    });
  }
}
