export default {
  vm: null,
  init(vm) {
    this.vm = vm;
  },
  onStateChange(callback) {
    this.vm.$root.$on("adv::loading::state", callback);
  },
  setState(state) {
    this.vm.$root.$emit("adv::loading::state", state);
  },
  showOverlay() {
    this.setState(true);
  },
  hideOverlay() {
    setTimeout(() => this.setState(false), 100);
  },
  async withOverlay(callback) {
    this.showOverlay();
    try {
      return await callback();
    } finally {
      this.hideOverlay();
    }
  },
};
