import BackEndpoints from "@/router/endpoints/BackEndpoints";
import Format from "@/domain/Format";

export default class CreativeSet {
  static get stateList() {
    return ["Draft", "Testing", "Ready", "Archived"];
  }

  static get formatList() {
    return [
      new Format({ name: "300x250", type: "square" }),
      new Format({ name: "120x600", type: "vertical" }),
      new Format({ name: "160x600", type: "vertical" }),
      new Format({ name: "300x600", type: "vertical" }),
      new Format({ name: "320x480", type: "vertical" }),
      new Format({ name: "200x200", type: "square" }),
      new Format({ name: "250x250", type: "square" }),
      new Format({ name: "500x500", type: "square" }),
      new Format({ name: "234x60", type: "horizontal" }),
      new Format({ name: "300x50", type: "horizontal" }),
      new Format({ name: "320x50", type: "horizontal" }),
      new Format({ name: "468x60", type: "horizontal" }),
      new Format({ name: "480x320", type: "horizontal" }),
      new Format({ name: "500x250", type: "horizontal" }),
      new Format({ name: "728x90", type: "horizontal" }),
      new Format({ name: "930x180", type: "horizontal" }),
      new Format({ name: "970x250", type: "horizontal" }),
    ];
  }

  constructor(props) {
    this.id = props.id;
    this.name = props.name;
    this.spreadsheetUrl = props.spreadsheetUrl;
    this.state = props.state;
    this.markets = props.markets;
    this.messages = props.messages;
    this.buildFileDriveId = props.buildFileDriveId;
    this.buildFileDriveName = props.buildFileDriveName;
    this.zipReady = props.zipReady;
    this.zipLastModified = props.zipLastModified;
    this.imageReady = props.imageReady;
  }

  get spreadsheetId() {
    let spreadsheetId = null;
    try {
      if (this.spreadsheetUrl) {
        spreadsheetId = new RegExp("/spreadsheets/d/([a-zA-Z0-9-_]+)").exec(
          this.spreadsheetUrl
        )[1];
      }
    } catch (ignored) {
      console.log(ignored);
    }

    return spreadsheetId;
  }

  get configReady() {
    return this.name && this.markets.length > 0;
  }

  get isReady() {
    return this.buildFileDriveId && this.configReady && this.spreadsheetUrl;
  }

  get backupImageUrl() {
    return BackEndpoints.toAbsolute(
      BackEndpoints.backupImage(this.spreadsheetId)
    );
  }

  get backupZipUrl() {
    return BackEndpoints.toAbsolute(
      BackEndpoints.backupZip(this.spreadsheetId)
    );
  }

  get buildFileUrl() {
    return (
      BackEndpoints.toAbsolute(
        BackEndpoints.mediaDownloadDrive(this.buildFileDriveId)
      ) + `?_=${Date.now()}`
    );
  }

  static empty() {
    return new CreativeSet({
      id: null,
      name: "",
      state: CreativeSet.stateList[0],
    });
  }
}
