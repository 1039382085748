<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.card-deck {
  .card {
    width: 300px;
    overflow: hidden;
    border-width: 2px;
    .card-header {
      color: #fff;
      font-weight: 500;
      font-size: 1.4rem;
      height: 12%;
      z-index: 10;
      &::after {
        content: "";
        top: 0;
        left: -10%;
        width: 120%;
        height: 20%;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        background-color: inherit;
        position: absolute;
        z-index: -1;
        box-shadow: 0 4px 10px rgb(198, 190, 190);
      }
    }
    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .card-text {
        color: #002806;
        font-weight: 500;
        font-size: 1.2rem;
      }
      .btn {
        font-family: "Helvetica";
        font-weight: 700;
        font-size: 2rem;
        line-height: 1;
      }
    }
  }
}

.forward {
  width: 50px;
  height: 50px;

  &_icon {
    filter: invert(100%) sepia(0%) saturate(3%) hue-rotate(121deg)
      brightness(107%) contrast(100%);
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.card-service {
  height: 350px;
}
</style>

<template>
  <b-link class="text-decoration-none" :to="route">
    <b-card
      class="card-service text-center rounded-4 m-0"
      :class="serviceClass"
      :border-variant="serviceClass"
      :header="serviceName"
      :header-bg-variant="serviceClass"
    >
      <b-card-group class="flex-column pt-5">
        <b-card-text class="card-link m-0 px-lg-3 text-center text-main">
          {{ description }}
        </b-card-text>
      </b-card-group>
      <b-button
        class="forward shadow text-center rounded-circle border-0 mb-3"
        :class="`bg-${serviceClass}`"
      >
        <img
          class="forward_icon"
          src="@/assets/images/pictos/forward.svg"
          alt=""
        />
      </b-button>
    </b-card>
  </b-link>
</template>

<script>
export default {
  name: "Card",
  props: {
    route: { type: Object, required: true },
    serviceClass: { type: String, required: true },
    serviceName: { type: String, required: true },
    description: { type: String, required: true },
  },
};
</script>
