export default class Email {
  constructor(props) {
    this.id = props.id;
    this.subject = props.subject;
    this.body = props.body;
  }

  static empty() {
    return new Email({
      id: null,
      subject: "Nouvelles modifications sur [STRATEGY] / [LANG]",
      body: "<html><body>New modifications for [STRATEGY] / [LANG]</p></body></html>",
    });
  }
}
