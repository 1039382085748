import ToastService from "@/services/ToastService";
import ErrorUtils from "@/utils/ErrorUtils";

import axios from "axios";
import BackEndpoints from "@/router/endpoints/BackEndpoints";

export default {
  async getSheets(ranges, spreadsheetId, token) {
    try {
      let response = await axios.post(
        BackEndpoints.withParams(BackEndpoints.getExternalSheets, {
          spreadsheetId,
        }),
        {
          ranges: ranges,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("getExternalSheets", response);

      response = response.data.valueRanges;

      const dataTables = [];

      response.map((sheet, indexSheet) => {
        const data = {
          headers: sheet.values.shift(),
          rows: [],
        };

        sheet.values.forEach((row) => {
          const rowMap = {};
          data.headers.forEach((colHeader, index) => {
            rowMap[colHeader] = row[index];
          });
          data.rows.push(rowMap);
        });

        dataTables[ranges[indexSheet]] = data;
      });

      return dataTables;
    } catch (error) {
      console.log("error", error);
      ToastService.error(
        "Error",
        `We couldn't get the spreadsheet with id :${spreadsheetId}. Error :${ErrorUtils.getMessage(
          error
        )}`
      );
      throw error;
    }
  },
};
