<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.edit-button {
  width: 3rem;
  align-self: flex-end;
}
.frame {
  border: 0;
}
</style>

<template>
  <div>
    <div class="d-flex flex-column align-items-center">
      <b-btn v-if="hasEdit && multiFormat" class="edit-button p-0 mb-2" variant="primary" @click="edit()">Edit</b-btn>
      <iframe class="frame" :width="width" :height="height" :name="initData" ref="iframe"></iframe>
      <p style="text-align: center" v-if="multiFormat">{{ width }}x{{ height }}</p>
      <b-btn v-if="hasEdit && !multiFormat" class="edit-button p-0 my-2" variant="primary" @click="edit()">Edit</b-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerPreview",
  data() {
    return {};
  },
  props: {
    name: { type: String, required: true },
    type: { type: String, required: true },
    url: { type: String, required: true },
    width: { type: Number, required: true },
    height: { type: Number, required: true },
    initData: { type: String, required: false },
    multiFormat: { type: Boolean, required: true },
    hasEdit: { type: Boolean, required: true },
  },
  watch: {
    url: function (newValue) {
      this.$refs.iframe.name = this.initData;
      this.$refs.iframe.src = newValue;
      this.$refs.iframe.replaceWith(this.$refs.iframe);
    },
  },
  methods: {
    refresh() {
      this.$emit("update-url", this.name);
    },
    edit() {
      this.$emit("open-modal", this.name, this.type, JSON.parse(this.initData));
    },
  },
  async mounted() {
    this.$refs.iframe.src = this.url;
  },
};
</script>
