<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

#fieldset {
  width: 100%;
  top: 0;
  padding-top: 10px;
  z-index: 1;
  background: $white;
}

.selector {
  width: fit-content;
  margin-top: -10px;
}

.multiformat-checkbox {
  display: inline;
}

.displayContainer {
  gap: 32px;
  padding: 16px;
}

.carouselContainer {
  height: fit-content;
  gap: 16px;
}

.share-button {
  width: calc(1.5em + 0.75rem + 2px);
  z-index: 4;
  position: relative;
}

.carouselControls {
  gap: 8px;
  width: 180px;
}

.selectorFormat {
  width: 120px;
}

.carouselArrow {
  max-width: 16px;
  cursor: pointer;
}

.backdrop {
  top: 0;
  left: 0;
  opacity: 0.5;
  background: $white;
  cursor: pointer;
  z-index: 2;
}
</style>

<template>
  <div
    class="margin-top-content"
    :style="displayModal && { width: '65%' }"
    :class="!displayModal && 'main-margins mx-auto'"
  >
    <b-form-group id="fieldset" :class="multiformatsCheckboxStatus && 'position-sticky'">
      <div class="d-flex align-items-center justify-content-around gap-3">
        <b-form-group>
          <label for="creativeSetSelect">Creative Set</label>
          <b-form-select
            id="creativeSetSelect"
            class="selector d-flex flex-column"
            v-model="selectedCreativeSetId"
            :options="creativeSetOptions"
            @change="changeCreativeSet"
          ></b-form-select>
        </b-form-group>

        <b-row class="align-items-center">
          <b-form-group>
            <label for="selectMarket">Market</label>
            <b-form-select
              id="selectMarket"
              class="selector d-flex flex-column mw-25 mx-auto"
              v-model="selectedMarket"
              :options="marketOptions"
              :disabled="!selectedCreativeSet || loadingMarket"
              @change="changeMarket"
            ></b-form-select>
          </b-form-group>
          <b-spinner class="ml-2" label="Loading..." v-if="loadingMarket"></b-spinner>
        </b-row>

        <b-form-group>
          <label for="selectMessage">Message</label>
          <b-form-select
            id="selectMessage"
            class="selector d-flex flex-column mw-25 mx-auto"
            v-model="selectedMessage"
            :options="messagesOptions"
            :disabled="!selectedCreativeSet || loadingMessage"
            @change="changeMessage"
          ></b-form-select>
        </b-form-group>

        <b-form-group class="mt-4">
          <b-form-checkbox
            id="multiformat-checkbox"
            class="multiformat-checkbox"
            v-model="multiformatsCheckboxStatus"
            name="multiformat-checkbox"
            :value="true"
            :unchecked-value="false"
            :disabled="!selectedMarket || !selectedMessage"
          ></b-form-checkbox>
          <label for="multiformat-checkbox" class="ml-2">Multi-format</label>
        </b-form-group>

        <div class="d-flex justify-content-start align-items-center">
          <b-btn
            class="border-primary color-primary h-50"
            variant="creativeLibrary"
            v-if="selectedMarket && selectedMessage && multiformatsCheckboxStatus === false"
            @click="replayCreative"
          >Replay
          </b-btn
          >
          <b-btn
            variant="creativeLibrary"
            class="border-primary color-primary h-50"
            v-if="selectedMarket && selectedMessage && multiformatsCheckboxStatus"
            @click="replayCreatives"
          >Replay All
          </b-btn
          >
        </div>

        <b-col class="flex-grow-0">
          <div
            class="d-flex align-items-center cursor-pointer"
            @click="displayShareModal = !displayShareModal"
            style="cursor: pointer"
          >
            <p class="m-0">Share</p>
            <img class="share-button ml-2" src="../assets/images/share.svg"/>
          </div>
          <ShareModal
            v-if="displayShareModal"
            :share-link="shareLink"
            :market-options="marketOptions"
            :creativesets-options="creativeSetOptions"
            :creative-sets="creativeSets"
            :disabled="!selectedCreativeSet || loadingMarket"
            :creative-set-id="selectedCreativeSetId"
            :user="user"
            :selected-market-prop="selectedMarket"
            :loading-market="loadingMarket"
            @update-share-link="updateShareLink"
            @close-modal="displayShareModal = !displayShareModal"
          />
        </b-col>
      </div>
      <div
        class="backdrop position-fixed w-100 h-100"
        v-if="displayShareModal"
        @click="displayShareModal = false"
      ></div>
    </b-form-group>

    <div
      class="creativeContainer d-flex align-items-center flex-column"
      v-if="selectedCreativeSet && selectedMarket && selectedMessage"
    >
      <div class="carouselControls d-flex justify-content-center mb-3" v-if="!multiformatsCheckboxStatus">
        <img class="carouselArrow" src="../assets/images/arrow.svg" @click="carouselPrev()"/>
        <b-form-select
          class="selectorFormat"
          v-model="selectedFormat"
          :options="formatOptions"
          @change="updateSelectedFormat"
        ></b-form-select>
        <img
          class="carouselArrow"
          style="transform: rotate(180deg)"
          src="../assets/images/arrow.svg"
          @click="carouselNext()"
        />
      </div>
      <div
        class="displayContainer d-flex flex-wrap justify-content-center align-items-end"
        v-if="multiformatsCheckboxStatus"
      >
        <div class="display" v-for="format in formats" :key="format.name">
          <BannerPreview
            id="preview"
            :name="format.name"
            :type="format.type"
            :height="format.height"
            :width="format.width"
            :url="format.url"
            :init-data="getInitData(format.name)"
            :multi-format="multiformatsCheckboxStatus"
            ref="creatives"
            @update-url="reloadIframe"
            @open-modal="editMessage"
            :has-edit="true"
          />
        </div>
      </div>
      <div class="carouselContainer d-flex flex-column align-items-center w-100" v-else>
        <div class="flex-grow-1 d-flex flex-column justify-content-center">
          <BannerPreview
            :name="formats[selectedFormat].name"
            :type="formats[selectedFormat].type"
            :height="formats[selectedFormat].height"
            :width="formats[selectedFormat].width"
            :url="formats[selectedFormat].url"
            :init-data="getInitData(formats[selectedFormat].name)"
            :multi-format="multiformatsCheckboxStatus"
            @update-url="reloadIframe"
            @open-modal="editMessage"
            :has-edit="true"
          />
        </div>
      </div>
    </div>
    <Transition name="slide-fade">
      <EditMessage
        v-if="displayModal && selectedMarket && selectedMessage"
        @close-modal="close"
        @submit="submit"
        @preview="preview"
        :size="currentFormat.size"
        :type="formats.find((el) => el.name === currentFormat.size).type"
        :cta-names-table="ctaSpreadsheet.CTA_list.rows"
        :assets-lang-table="spreadsheet.Assets_lang.rows"
        :assets-design-table="spreadsheet.Assets_design.rows"
        :market="selectedMarket"
        :message="selectedMessage"
        :loading="loadingSave"
      />
    </Transition>
    <Adblock/>
  </div>
</template>

<script>
import CreativeSet from "@/domain/CreativeSet";
import BackEndpoints from "@/router/endpoints/BackEndpoints";
import CreativeSetService from "@/services/CreativeSetService";
import SpreadsheetService from "@/services/SpreadsheetService";
import ToastService from "@/services/ToastService";
import ErrorUtils from "@/utils/ErrorUtils";
import BannerPreview from "@/views/liveEdit/BannerPreview";
import ShareModal from "@/views/liveEdit/ShareModal.vue";
import axios from "axios";
import Adblock from "./liveEdit/Adblock.vue";
import EditMessage from "./liveEdit/EditMessage.vue";

export default {
  name: "LiveEdit",
  title: "Creative Library - Disneyland CMP",
  props: ["user"],
  data() {
    return {
      loadingSave: false,
      loadingMarket: false,
      loadingMessage: true,
      displayShareModal: false,
      shareLink: "",
      formats: CreativeSet.formatList,
      initDataMap: {},

      multiformatsCheckboxStatus: false,
      creativeSets: [],
      selectedCreativeSetId: this.creativeSetId || null,
      creativeSetOptions: [{value: null, text: "Select a creative set"}],

      selectedMarket: null,

      marketOptions: [{value: null, text: "Select a market"}],

      selectedMessage: null,
      messagesOptions: [{value: null, text: "Select a message"}],

      selectedFormat: 0,
      formatOptions: [],

      displayModal: false,
      currentFormat: {
        size: "",
        type: "",
      },

      spreadsheet: [],
      ctaSpreadsheet: [],

      previewInitData: null,
      originalInitData: null,
    };
  },

  components: {
    ShareModal,
    BannerPreview,
    EditMessage,
    Adblock,
  },
  computed: {
    creativeSetId() {
      return this.$route.params.creativeSetId;
    },
    selectedCreativeSet() {
      return this.creativeSets.find((creativeSet) => {
        return creativeSet.id === this.selectedCreativeSetId;
      });
    },

    frameInitDataPreview() {
      return this.previewInitData?.data.frames.frame1.data;
    },

    globalInitDataPreview() {
      return this.previewInitData?.data.global;
    },

    updateSpreadsheet() {
      return BackEndpoints.update;
    },
    originalInitDataFrames() {
      return this.originalInitData.data.frames.frame1.data;
    },
    originalInitDataGlobal() {
      return this.originalInitData.data.global;
    },
  },
  methods: {
    async updateShareLink(values) {
      if (values.shareMode === "All") {
        this.shareLink = await CreativeSetService.getShareUrl();
      } else if (values.shareMode === "Market") {
        if (values.selectedMarketOnly) {
          this.shareLink = await CreativeSetService.getShareUrl(values.selectedMarketOnly);
        } else {
          this.shareLink = "";
        }
      } else if (values.shareMode === "MarketAndCreativeSet") {
        if (values.selectedMarketMC && values.selectedCreativeSetMC) {
          this.shareLink = await CreativeSetService.getShareUrl(values.selectedMarketMC, values.selectedCreativeSetMC);
        } else {
          this.shareLink = "";
        }
      }
    },
    getInitData(size) {
      if (this.previewInitData === null) {
        const initData = this.initDataMap[this.selectedMarket + this.selectedMessage + size];

        const initDataUrlEmpty = global.structuredClone(initData);

        initDataUrlEmpty.data.frames.frame1.data.url = "";
        return JSON.stringify(initDataUrlEmpty);
      } else {
        return JSON.stringify(this.previewInitData);
      }
    },
    getInitialInitData(size) {
      let initData = this.initDataMap[this.selectedMarket + this.selectedMessage + size];

      return JSON.stringify(initData);
    },
    changeCreativeSet() {
      this.refreshUrl();
      this.messagesOptions = [{value: null, text: "Select a message"}];
      this.marketOptions = [{value: null, text: "Select a market"}];
      this.selectedMarket = null;
      this.selectedMessage = null;
      this.selectedCreativeSetId && this.readCreativeSetSheet();
    },
    changeMarket() {
      this.previewInitData = null;
      this.messagesOptions = [{value: null, text: "Select a message"}];
      this.selectedMessage = null;
      this.getMessages();
    },
    changeMessage() {
      this.previewInitData = null;
      this.$router.push({
        query: {
          market: this.selectedMarket,
          message: this.selectedMessage,
        },
      });
    },
    replay() {
      if (this.multiformatsCheckboxStatus) {
        this.replayCreatives();
      } else {
        this.replayCreative();
      }
    },
    updateSelectedFormat() {
      this.currentFormat.size = this.formatOptions[this.selectedFormat].text;
      this.currentFormat.type = this.formats[this.selectedFormat].type;
      this.previewInitData = null;
      this.replay();
    },
    carouselPrev() {
      if (this.selectedFormat === 0) {
        this.selectedFormat = this.formats.length - 1;
      } else {
        this.selectedFormat--;
      }
      this.currentFormat.size = this.formatOptions[this.selectedFormat].text;
      this.currentFormat.type = this.formats[this.selectedFormat].type;
      this.previewInitData = null;
      this.replay();
    },
    carouselNext() {
      if (this.selectedFormat === this.formats.length - 1) {
        this.selectedFormat = 0;
      } else {
        this.selectedFormat++;
      }
      this.currentFormat.size = this.formatOptions[this.selectedFormat].text;
      this.currentFormat.type = this.formats[this.selectedFormat].type;
      this.previewInitData = null;
      this.replay();
    },
    changePreviewInitData() {
      if (this.previewInitData) {
        const parsedPreviewInitData = this.previewInitData;
        const parsedOriginalInitData = JSON.parse(this.getInitialInitData(this.currentFormat.size));
        this.previewInitData = {
          data: {
            frames: {...parsedPreviewInitData.data.frames},
            global: {
              ...parsedOriginalInitData.data.global,
              logoTransform: parsedPreviewInitData.data.global.logoTransform,
              videoTransform: parsedPreviewInitData.data.global.videoTransform,
            },
          },
          env: {
            name: "disneyland-cmp-banner-validation",
          },
        };
      }
    },

    refreshUrl() {
      this.$router.push({
        name: "LiveEditPreselect",
        params: {creativeSetId: this.selectedCreativeSetId},
      });
    },
    reloadIframe(formatName) {
      const format = this.formats.find((el) => el.name === formatName);
      format.updateUrl();
    },
    replayCreative() {
      const format = this.formats[this.selectedFormat];
      format.updateUrl();
    },
    replayCreatives() {
      this.$refs.creatives.forEach((element) => element.refresh());
    },
    async readCreativeSetSheet() {
      this.loadingMarket = true;
      this.loadingMessage = true;
      this.initDataMap = {};
      SpreadsheetService.setToken(this.user.oAuthAccessToken);

      this.ctaSpreadsheet = await SpreadsheetService.getSheets(
        ["CTA_list", "CTAStyle_list"],
        SpreadsheetService.CTA_SPREADSHEET_ID
      );

      this.spreadsheet = await SpreadsheetService.getSheets(
        ["scenarios", "Assets_lang", "Assets_design"],
        this.selectedCreativeSet.spreadsheetId
      );

      try {
        if (!this.spreadsheet.scenarios.headers.includes(SpreadsheetService.SCENARIO_COL)) {
          throw Error("No column #scenario found.");
        }
        if (!this.spreadsheet.scenarios.headers.includes(SpreadsheetService.SIZE_COL)) {
          throw Error("No column #size found.");
        }

        if (!this.spreadsheet.scenarios.headers.includes(SpreadsheetService.INITDATA_COL)) {
          throw Error("No column json found.");
        }
      } catch (error) {
        ToastService.error("Error", `Error parsing spreadsheet headers :${ErrorUtils.getMessage(error)}`);
      }

      this.spreadsheet.scenarios.rows.forEach((row) => {
        const scenario = row[SpreadsheetService.SCENARIO_COL];
        const initDataT = row[SpreadsheetService.INITDATA_COL];
        const size = row[SpreadsheetService.SIZE_COL];
        const [market, message] = scenario.split("*");

        if (!this.marketOptions.find((element) => element.value === market)) {
          this.marketOptions.push({
            value: market,
            text: market,
          });
        }

        this.initDataMap[market + message + size] = {
          data: JSON.parse(initDataT),
          env: {
            name: "disneyland-cmp-banner-validation",
          },
        };
      });

      this.loadingMarket = false;
    },

    getMessages() {
      if (this.selectedCreativeSetId && this.selectedMarket) {
        this.loadingMessage = true;

        this.spreadsheet.scenarios.rows.forEach((row) => {
          const scenario = row[SpreadsheetService.SCENARIO_COL];
          const initDataT = row[SpreadsheetService.INITDATA_COL];
          const size = row[SpreadsheetService.SIZE_COL];
          const [market, message] = scenario.split("*");

          if (market === this.selectedMarket) {
            if (!this.messagesOptions.find((element) => element.value === message)) {
              this.messagesOptions.push({
                value: message,
                text: message,
              });
            }
          }

          this.initDataMap[market + message + size] = {
            data: JSON.parse(initDataT),
            env: {
              name: "disneyland-cmp-banner-validation",
            },
          };
        });

        this.loadingMessage = false;
      }
    },
    editMessage(size, type, initdata) {
      this.displayModal = true;
      this.currentFormat.size = size;
      this.currentFormat.type = type;

      this.selectedFormat = this.formatOptions.find((el) => el.text === size).value;
      this.originalInitData = initdata;
      this.multiformatsCheckboxStatus = false;
    },
    close() {
      this.displayModal = false;
      this.previewInitData = null;
      this.replay();
    },
    getLogoInitData() {
      const logo = this.originalInitDataGlobal.logo;
      const lastIndex = logo.lastIndexOf("/");
      return logo.slice(lastIndex + 1);
    },

    formatBackground() {
      if (
        this.originalInitDataGlobal.background.includes(".jpg") ||
        this.originalInitDataGlobal.background.includes(".png") ||
        this.originalInitDataGlobal.background.includes(".svg") ||
        this.originalInitDataGlobal.background.includes(".jpeg") ||
        this.originalInitDataGlobal.background.includes(".gif")
      ) {
        const nameFile = this.originalInitDataGlobal.background.split("background/")[1].split("/")[0];
        const extension = this.originalInitDataGlobal.background.split(".").pop();

        return `${nameFile}.${extension}`;
      } else {
        return this.originalInitDataGlobal.background;
      }
    },
    async submit(values) {
      const params = {
        market: this.selectedMarket,
        message: this.selectedMessage,
        size: this.currentFormat.size,
      };
      const dataLang = {
        tagline: values.text,
        offer: values.offer,
        cta: values.cta,
        ml: values.terms,
        ctaStyle: values.ctaStyle,
      };

      const dataDesign = {
        colorTxt: this.originalInitDataFrames.colorTxt,
        bgCta: this.originalInitDataFrames.bgCta,
        colorCta: this.originalInitDataFrames.colorCta,
        colorCopyright: this.originalInitDataGlobal.colorCopyright,
        logo: this.getLogoInitData(),
        background: this.formatBackground(),
        logoTransform: `translate(${values.logoTransform.x}px,${values.logoTransform.y}px) scale(${values.logoTransform.scale}%)`,
        videoTransform: `translate(${values.videoTransform.x}px,${values.videoTransform.y}px)`,
      };

      const payload = {
        gssid: this.selectedCreativeSet.spreadsheetId,
        market: this.selectedMarket,
        message: this.selectedMessage,
        size: this.currentFormat.size,
      };

      try {
        if (!values.ctaStyle) {
          ToastService.error("Error", `Error because CTA style is empty`);
        } else {
          this.loadingSave = true;
          await SpreadsheetService.batchUpdateValues(
            this.selectedCreativeSet.spreadsheetId,
            params,
            dataLang,
            dataDesign
          );
          await axios.get(this.updateSpreadsheet, {params: payload});
          this.previewInitData = null;
          await this.readCreativeSetSheet();
          this.loadingMessage = false;
          this.loadingSave = false;

          this.replay();
          ToastService.success("Success", `The spreadsheet has been updated`);
        }
      } catch (error) {
        ToastService.error("Error", `Error on save :${ErrorUtils.getMessage(error)}`);
      }
    },

    preview(values) {
      if (!values.ctaStyle) {
        ToastService.error("Error", `Error because CTA style is empty`);
      } else {
        this.previewInitData = this.originalInitData;
        this.frameInitDataPreview.txt1 = this.formatText(values.text);
        this.frameInitDataPreview.txt2 = this.formatText(values.offer);
        this.frameInitDataPreview.terms = this.formatText(values.terms);

        this.frameInitDataPreview.cta = this.formatText(this.convertDataCta(values.cta, values.ctaStyle));
        this.frameInitDataPreview.borderCta = this.getCtaStyle(values.ctaStyle, "borderCta");
        this.frameInitDataPreview.borderRadiusCta = this.getCtaStyle(values.ctaStyle, "borderRadiusCta");

        this.globalInitDataPreview.logoTransform = `translate(${values.logoTransform.x}px,${values.logoTransform.y}px) scale(${values.logoTransform.scale}%)`;
        this.globalInitDataPreview.videoTransform = `translate(${values.videoTransform.x}px,${values.videoTransform.y}px)`;
        this.currentFormat.size = this.formatOptions[this.selectedFormat].text;
        this.currentFormat.type = this.formats[this.selectedFormat].type;

        this.changePreviewInitData();
        this.replay();
      }
    },

    convertDataCta(ctaName, ctaStyle) {
      const findByName = this.ctaSpreadsheet.CTA_list.rows.find(
        (el) =>
          el["#Size Category"] === this.currentFormat.size &&
          el["#Cta style"] === ctaStyle &&
          el["#Cta list"] === ctaName &&
          el["lang"].includes(this.selectedMarket)
      );
      if (!findByName) {
        const findByType = this.ctaSpreadsheet.CTA_list.rows.find(
          (el) =>
            el["#Size Category"] === this.currentFormat.type &&
            el["#Cta style"] === ctaStyle &&
            el["#Cta list"] === ctaName &&
            el["lang"].includes(this.selectedMarket)
        );
        return findByType["cta result"];
      } else {
        return findByName["cta result"];
      }
    },

    getCtaStyle(type, style) {
      const findByType = this.ctaSpreadsheet.CTAStyle_list.rows.find(
        (el) => el["size"] === this.currentFormat.type && el["type"] === type
      );
      return findByType[style];
    },

    formatText(text) {
      if (
        text.indexOf(".jpg") > -1 ||
        text.indexOf(".jpeg") > -1 ||
        text.indexOf(".svg") > -1 ||
        text.indexOf(".png") > -1 ||
        text.indexOf(".gif") > -1
      ) {
        return text;
      } else {
        return text.replace(/_/g, "&nbsp;");
      }
    },
  },
  watch: {
    selectedMessage(message) {
      if (!message) {
        this.displayModal = false;
      } else {
        this.currentFormat.size = this.formats[this.selectedFormat].name;
        this.currentFormat.type = this.formats[this.selectedFormat].type;
        this.getInitData(this.currentFormat.size);
        this.previewInitData = null;
        this.replay();
      }
    },
    selectedMarket(market) {
      if (!market) {
        this.displayModal = false;
      }
    },
    multiformatsCheckboxStatus() {
      if (this.multiformatsCheckboxStatus) this.displayModal = false;
    },
  },
  async mounted() {
    await SpreadsheetService.initClient();

    this.creativeSets = await CreativeSetService.list();

    if (this.creativeSetId) {
      this.selectedCreativeSetId = this.creativeSetId;
    }

    this.creativeSets.forEach((el) => {
      this.creativeSetOptions.push({value: el.id, text: el.name});
    });

    this.formats.forEach((format, index) => {
      this.formatOptions.push({value: index, text: format.name});
    });

    this.selectedCreativeSetId && (await this.readCreativeSetSheet());

    if (this.$route.query.market) {
      this.selectedMarket = this.$route.query.market;
      await this.getMessages();
    }

    const urlMessageOption = this.messagesOptions.find((message) => {
      return message.text === this.$route.query.message;
    });

    if (urlMessageOption) {
      this.selectedMessage = urlMessageOption.value;
    }
  },
};
</script>
