<template>
  <div id="div-404">
    <div>404</div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  title: "Page not found - Disneyland CMP",
}
</script>

<style scoped lang="scss">
#div-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-size: 404px;
  text-shadow: 10px 10px .404px;
  opacity: .404;

  a {
    text-decoration: none;
  }
}
</style>
