<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
</style>
<style>
.main-margins .markets-container .dropdown .dropdown-menu {
  max-height: 200px;
  width: 100%;
  overflow-y: scroll;
}

.icon {
  width: 6rem;
}
</style>

<template>
  <div
    class="w-100 d-flex mx-auto justify-content-center align-items-center full-main-height"
    v-if="markets.length === 0"
  >
    <b-spinner label="Loading..."></b-spinner>
  </div>
  <div class="d-flex flex-column main-margins mx-auto" v-else>
    <b-btn
      v-if="!displayOfferView"
      @click="goBackStrategy"
      variant="outline-primary"
      class="font-weight-normal text-uppercase back-btn mt-5"
      style="height: 48px; width: 90px"
    >&lt; Back
    </b-btn>
    <div
      :class="!displayOfferView && 'd-flex justify-content-center align-items-center gap-1 flex-grow-1'"
    >
      <OffersView
        v-if="displayOfferView"
        :data-prop-active="dataSelectedMarketActive"
        :data-prop-inactive="dataSelectedMarketInactive"
        :default-message="dataSelectedMarketDefault"
        :data-settings="dataSettings"
        :market-prop="selectedMarket"
        :spreadsheet-id="spreadsheetId"
        :max-id="maxId"
        @go-back="goBackOfferView"
        @refresh="fetchData(true)"
      />
      <div v-else>
        <div class="d-flex flex-column gap-1 align-items-center">
          <img class="icon" src="@/assets/images/pictos/loudspeaker-icon.svg"/>
          <h4 v-if="strategy === 'retargeting'">Market overview</h4>
          <h4 v-else>Select a market</h4>
        </div>

        <div
          class="d-flex flex-wrap justify-content-center gap-1 my-5"
        >
          <div v-for="market in markets" :key="market.market">
            <Market
              v-if="groupsMessagesLive[market.market]"
              :name="market.market"
              @get-data="getData"
              :groups="groupsMessagesLive[market.market]"
              :disabled="strategy === 'retargeting'"
            />
          </div>
        </div>
        <span></span>
      </div>
    </div>
    <div style="height: 48px"></div>
  </div>
</template>

<script>
import DecisionTreeService from "@/services/DecisionTreeService";
import Market from "@/views/decisionTree/Market.vue";
import FrontEndpoints from "@/router/endpoints/FrontEndpoints";
import OffersView from "@/views/decisionTree/OffersView.vue";
import axios from "axios";
import BackEndpoints from "@/router/endpoints/BackEndpoints";
import SpreadsheetService from "@/services/SpreadsheetService";

export default {
  name: "SubMenu",
  components: {OffersView, Market},
  title: "Decision Tree - Disneyland CMP",
  data() {
    return {
      markets: [],
      displayOfferView: false,
      strategy: this.$route.params.strategy,
      dataSelectedMarketActive: [],
      dataSelectedMarketInactive: [],
      dataSelectedMarketDefault: {},
      selectedMarket: this.$route.params.market,
      dataSettings: [],
      spreadsheetId: "",
      maxId: 0,
      groupsMessagesLive: {},
    };
  },
  props: ["user"],
  watch: {
    displayOfferView(isDisplayed) {
      if (isDisplayed) {
        this.$emit("update-sub-service-name", "Offers View");
      } else {
        this.$emit("update-sub-service-name", this.strategy);
      }
    },
  },
  methods: {
    updateUrl() {
      if (!Object.keys(DecisionTreeService.messagesTypes).includes(this.strategy)) {
        return this.$router.push(FrontEndpoints.decisionTree);
      }
    },
    async getDataMarket() {
      const marketSheet = this.markets.find((market) => {
        return market.market === this.selectedMarket;
      });

      if (marketSheet) {
        // TODO attention aux autres gsheets ("MessageOffer")
        const defaultMessage = marketSheet.data.rows.find((row) => {
          return row["MessageNumber"] === "Default" || row["MessageOffer"] === "Default";
        });

        const newDataActive = marketSheet.data.rows.filter((row) => {
          return row["Statut"] === "active" && row["MessageNumber"] !== "Default" && row["StartDate"] && row["EndDate"];
        });

        const newDataInactive = marketSheet.data.rows.filter((row) => {
          return (
            row["Statut"] === "inactive" && row["MessageNumber"] !== "Default" && row["StartDate"] && row["EndDate"]
          );
        });

        this.dataSelectedMarketActive = newDataActive;
        this.dataSelectedMarketInactive = newDataInactive;
        this.dataSelectedMarketDefault = defaultMessage;

        this.maxId = Math.max(
          ...marketSheet.data.rows
            .map((message) => {
              return message["#id"];
            })
            .map(Number)
        );
      }

      const {data} = await axios.get(BackEndpoints.getCreativeSetsMessages, {
        params: {name: this.selectedMarket},
      });

      this.dataSettings = data;
    },
    getData(name) {
      this.displayOfferView = true;
      this.selectedMarket = name;

      this.$router.push({
        name: "OffersView",
        params: {
          strategy: this.strategy,
          market: name,
        },
      });

      this.getDataMarket();
    },

    goBackOfferView() {
      this.displayOfferView = false;

      this.$router.push({
        name: "DecisionTreeStrategy",
        params: {
          strategy: this.strategy,
        },
      });
    },
    goBackStrategy() {
      this.displayOfferView = false;

      this.$router.push({
        name: "DecisionTree",
      });
    },
    async fetchData(isRefreshing = false) {
      if (isRefreshing) {
        this.markets = [];
      }
      const resp = await DecisionTreeService.getStrategiesSheet(this.strategy);
      this.markets = resp.dataTables;
      this.spreadsheetId = resp.spreadsheetId;

      if (this.$route.params.market) {
        await this.getDataMarket();
        !this.displayOfferView && (this.displayOfferView = true);
      }
    },
    getGroupsLivesMessages() {
      this.markets.forEach((market) => {
        this.$set(this.groupsMessagesLive, market.market, {});
        market.data.rows.forEach((message) => {
          const nameMessage = message?.MessageOffer ?? message.MessageNumber;
          if (nameMessage === "Default" || message.Statut === 'inactive') return;
          if (!this.groupsMessagesLive[market.market][nameMessage]) {
            this.groupsMessagesLive[market.market][nameMessage] = false;
          }
          if (DecisionTreeService.dateIsNowOrBefore(message.StartDate) && DecisionTreeService.dateIsNowOrAfter(message.EndDate)) {
            this.groupsMessagesLive[market.market][nameMessage] = true;
          }
        });
      });
    },
    getGroupsMarketRetargeting() {
      this.markets.forEach((market) => {
        this.$set(this.groupsMessagesLive, market.market, {});
        this.groupsMessagesLive[market.market]["Unknown User"] = false;
        this.groupsMessagesLive[market.market]["DLP Intenders"] = false;

        market.data.rows.forEach((row) => {
          if (this.groupsMessagesLive[market.market]["Unknown User"] && this.groupsMessagesLive[market.market]["DLP Intenders"]) {
            return;
          }

          const isActivated = row["activation"] === "TRUE";
          const isDateBetweenRange = DecisionTreeService.dateIsNowOrBefore(row.startDate) && DecisionTreeService.dateIsNowOrAfter(row.endDate);

          if (!this.groupsMessagesLive[market.market]["Unknown User"]) {
            const isUnknowUser = [
              row["#audience"] === DecisionTreeService.RETARGETING.ALL && row["#action"] === DecisionTreeService.RETARGETING.UNKNOWNUSER,
              isActivated,
              isDateBetweenRange,
            ];

            if (isUnknowUser.every((item) => item === true)) {
              this.groupsMessagesLive[market.market]["Unknown User"] = true;
            }
          }

          if (!this.groupsMessagesLive[market.market]["DLP Intenders"]) {
            const DLPIntenders = [
              row["#audience"] === DecisionTreeService.RETARGETING.DLPINTENDERS && row["#action"] === DecisionTreeService.RETARGETING.HOMEPAGE,
              isActivated,
              isDateBetweenRange,
            ];

            if (DLPIntenders.every((item) => item === true)) {
              this.groupsMessagesLive[market.market]["DLP Intenders"] = true;
            }
          }
        });
      });
    },
  },
  async mounted() {
    await SpreadsheetService.initClient();
    await SpreadsheetService.setToken(this.user.oAuthAccessToken);
    await this.fetchData();
    this.updateUrl();

    if (this.strategy === "retargeting") {
      this.getGroupsMarketRetargeting();
    } else {
      this.getGroupsLivesMessages();
    }

    if (!this.displayOfferView) {
      this.$emit("update-sub-service-name", this.strategy);
    } else {
      this.$emit("update-sub-service-name", "Offers View");
    }
  },
};
</script>
