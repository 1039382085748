import axios from "axios";

import BackEndpoints from "@/router/endpoints/BackEndpoints";
import router from "@/router";
import ToastService from "@/services/ToastService";
import ErrorUtils from "@/utils/ErrorUtils";

import FrontEndpoints from "@/router/endpoints/FrontEndpoints";

export default {
  init() {
    this.initAuthUser();
    this.initAxiosInterceptor();
  },
  initAuthUser() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.access_token) {
      this.saveAuthUser(user);
    }
  },
  initAxiosInterceptor() {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          this.clearAuthUser();
          this.savePathAndRedirectToLogin();
        }
        return Promise.reject(error);
      }
    );
  },
  saveAuthUser(user) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${user.access_token}`;
    localStorage.setItem("user", JSON.stringify(user));
  },
  clearAuthUser() {
    axios.defaults.headers.common["Authorization"] = null;
    localStorage.removeItem("user");
  },
  savePathAndRedirectToLogin() {
    if (router.currentRoute.name !== "Login") {
      sessionStorage.setItem("redirectPath", router.currentRoute.path);
      router.push({ name: "Login" });
    }
  },
  clearPathAndRedirectToLogin() {
    sessionStorage.removeItem("redirectPath");
    let loginRoute = router.options.routes.find((route) => {
      return route.name === "Login"
    });
    window.location.href = loginRoute.path;
  },
  redirectToSavedPath() {
    const redirectPath = sessionStorage.getItem("redirectPath");
    sessionStorage.removeItem("redirectPath");
    router.replace(redirectPath || FrontEndpoints.home);
  },
  logout() {
    this.clearAuthUser();
    this.clearPathAndRedirectToLogin();
    ToastService.info("Logout", "You have been logged out.");
  },
  onLoginSuccess(user) {
    this.saveAuthUser(user);
    this.redirectToSavedPath();
  },
  async login(username, password) {
    try {
      let response = await axios.post(BackEndpoints.login, {
        username,
        password,
      });
      let user = response.data;
      if (user?.access_token) {
        this.onLoginSuccess(user);
        return user;
      } else {
        ToastService.error("Login error", "No access token");
        return Promise.reject(user);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        this.clearAuthUser();
        ToastService.error("Login error", "Authentication failed");
      } else {
        ToastService.error("Login error", ErrorUtils.getMessage(error));
      }
      return Promise.reject(error);
    }
  },
  validateOauthToken() {
    const queryParams = router.currentRoute.query;
    const access_token = queryParams.token;
    const error = queryParams.error;
    const message = queryParams.message;
    if (access_token) {
      const user = { access_token };
      this.onLoginSuccess(user);
      return user;
    } else if (error) {
      ToastService.error("OAuth error", message);
      return Promise.reject(new Error(message));
    }
    // No oauth token
    return null;
  },
};
