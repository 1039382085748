import axios from "axios";
import BackEndpoints from "@/router/endpoints/BackEndpoints";
import ToastService from "@/services/ToastService";
import ErrorUtils from "@/utils/ErrorUtils";

export default {
  messagesTypes: {
    affiliation: "MessageOffer",
    dataProvider: "MessageNumber",
    acquisition: "",
    retargeting: "",
  },

  RETARGETING: {
    ALL: "ALL",
    UNKNOWNUSER: "UNKNOWNUSER",
    DLPINTENDERS: "DLPINTENDERS",
    HOMEPAGE: "HOMEPAGE",
  },

  async getStrategiesSheet(strategy) {
    let formatTextStrategy = strategy.charAt(0).toUpperCase() + strategy.slice(1);

    if (strategy === Object.keys(this.messagesTypes)[1]) {
      formatTextStrategy = "Sojern";
    }

    try {
      const resp = await axios.get(BackEndpoints.getStrategiesSheet, {
        params: { strategy: formatTextStrategy },
      });

      if (!resp.data.length) {
        return ToastService.error(`Error when reading ${strategy} spreadsheet`, "Rate limit exceeded due to frequent refreshes. Please wait a few minutes before trying again.")
      }

      const filterResp = resp.data[0].filter(
        (data) => !data.market.includes("Summary") && data.market.includes(formatTextStrategy)
      );

      const renameData = filterResp.map((data) => {
        return { market: data.market.split("_")[1], details: data.details };
      });

      const respSort = renameData.sort((market1, market2) => market1.market.localeCompare(market2.market));

      const dataTables = [];

      respSort.map((sheet, indexSheet) => {
        const data = {
          headers: sheet.details.shift(),
          rows: [],
        };

        sheet.details.forEach((row) => {
          const rowMap = {};
          data.headers.forEach((colHeader, index) => {
            rowMap[colHeader] = row[index];
          });
          data.rows.push(rowMap);
        });

        dataTables[indexSheet] = { market: sheet.market, data: data };
      });

      return { dataTables, spreadsheetId: resp.data[1].spreadsheetId };
    } catch (e) {
      console.log(e);
      ToastService.error(`Error when reading ${strategy} spreadsheet`, ErrorUtils.getMessage(e));
      throw e;
    }
  },

  parseDate(dateStr) {
    const components = dateStr.split("/");
    const day = parseInt(components[0]);
    const month = parseInt(components[1]) - 1;
    const year = parseInt(components[2]);
    return new Date(year, month, day);
  },

  dateIsNowOrAfter(dateStr) {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return this.parseDate(dateStr) >= now;
  },

  dateIsNowOrBefore(dateStr) {
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return this.parseDate(dateStr) <= now;
  },
};
