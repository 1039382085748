<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
.message {
  width: fit-content;
}
.markets-container{
  height: fit-content;
}
.collapse{
  transition: all 0s ease-in-out;
}
button.not-collapsed:focus{
  background-color: $primary !important;
  color: $light;
}
</style>

<template>
  <b-form
    class="mx-auto main-margins mx-auto margin-top-content"
    @submit="submit"
  >
    <b-row class="my-4">
      <b-col sm="3">
        <label for="name">Name</label>
      </b-col>
      <b-col sm="9">
        <b-form-input
          id="name"
          name="name"
          required
          v-model="creativeSet.name"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row class="my-4">
      <b-col sm="3">
        <label for="file">Build file</label>
      </b-col>
      <b-col sm="7">
        <b-form-file
          name="file"
          :state="Boolean(creativeSet.buildFileDriveId)"
          ref="file"
          @change="handleFileUpload"
          :placeholder="
            creativeSet.buildFileDriveName
              ? creativeSet.buildFileDriveName
              : 'Choose a file or drop it here...'
          "
          drop-placeholder="Drop file here..."
        ></b-form-file>
      </b-col>
      <b-col sm="2">
        <b-spinner class="ml-2" label="Loading..." v-if="loading"></b-spinner>
        <a
          v-if="creativeSet.buildFileDriveId && !loading"
          :href="creativeSet.buildFileUrl"
          >Download</a
        >
      </b-col>
    </b-row>
    <b-row class="my-4 markets-container" v-if="!createMode">
      <b-col sm="3">
        <label for="Messages">Markets & Messages</label>
      </b-col>
      <b-col sm="9">
        <b-button
          v-for="market in creativeSet.markets"
          v-b-toggle="market.id"
          :key="market.id"
          class="m-0 p-1 mr-1 mt-1 market-btn bg-light border-primary"
          >{{ market.name }}</b-button
        >

        <b-collapse
          v-for="market in creativeSet.markets"
          :id="market.id"
          :key="market.id + market.name"
          accordion="my-accordion"
          class="mt-2 collapse"
        >
          <p class="d-inline-block mr-1 font-weight-bold text-primary">Messages in {{ market.name }} :</p>
          <div
            v-for="message in market.messages"
            :key="message.id"
            class="message m-0 p-1 mr-1 mt-1 bg-primary text-white rounded d-inline-flex"
          >
            {{ message.name }}
          </div>
        </b-collapse>
      </b-col>
    </b-row>
    <b-row class="my-4">
      <b-col sm="3">
        <label for="state">State</label>
      </b-col>
      <b-col sm="9">
        <b-form-select
          id="state"
          v-model="creativeSet.state"
          :options="stateList"
        ></b-form-select>
      </b-col>
    </b-row>
    <b-row class="my-4">
      <b-col sm="3">
        <label for="spreadsheetUrl">Spreadsheet Url</label>
      </b-col>
      <b-col sm="9">
        <b-form-input
          id="spreadsheetUrl"
          name="spreadsheetUrl"
          v-model="creativeSet.spreadsheetUrl"
          :readonly="!user.isAdmin"
          :state="isValidUrl()"
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row class="my-4 justify-content-between">
      <b-btn
        type="reset"
        class=" border-white text-white" variant="projectManagement"
        :to="{ name: 'CreativeSets' }"
        >Return</b-btn
      >
      <b-btn type="submit" class="border-success text-white" variant="success" :disabled="loading"
        >Save</b-btn
      >
    </b-row>
  </b-form>
</template>

<script>
import User from "@/domain/User";
import CreativeSet from "@/domain/CreativeSet";
import CreativeSetService from "@/services/CreativeSetService";
import MediaService from "@/services/MediaService";
import UserService from "@/services/UserService";

export default {
  name: "CreativeSet",
  title: "Project Management - Disneyland CMP",

  data() {
    return {
      user: User.empty(),
      creativeSet: CreativeSet.empty(),
      creativeSetMarkets: [],
      stateList: CreativeSet.stateList,
      createMode: this.$route.name === "CreativeSetCreate",
      loading: false,
      // selectedMarket: false,
    };
  },
  computed: {
    creativeSetId() {
      return this.$route.params.creativeSetId;
    },
  },
  methods: {
    isValidUrl() {
      try {
        new RegExp("/spreadsheets/d/([a-zA-Z0-9-_]+)").exec(
          this.creativeSet.spreadsheetUrl
        )[1];
        return true;
      } catch (e) {
        return false;
      }
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];
      console.log("file", file);
      if (!file) return;
      const formData = new FormData();
      if (this.creativeSet.buildFileDriveId) {
        formData.append("id", this.creativeSet.buildFileDriveId);
      }
      formData.append("name", file.name);
      formData.append("file", file, file.name);
      this.loading = true;
      let resp = await MediaService.mediaUploadDrive(formData).finally(() => {
        this.loading = false;
      });

      this.creativeSet.buildFileDriveId = resp.buildFileDriveId;
      this.creativeSet.buildFileDriveName = resp.buildFileDriveName;
    },
    async submit(event) {
      event.preventDefault();
      await (this.createMode
        ? CreativeSetService.create(this.creativeSet)
        : CreativeSetService.update(this.creativeSet));
      this.$router.push({ name: "CreativeSets" });
    },
  },
  async mounted() {
    this.user = await UserService.getCurrentUser();
    if (this.createMode) {
      this.creativeSet = CreativeSet.empty();
    } else {
      this.creativeSet = await CreativeSetService.get(this.creativeSetId);
    }

    if (!this.createMode) {
      this.creativeSetMarkets = this.creativeSet.markets.map((value) => {
        return value.name;
      });
    }
  },
};
</script>
