<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";
.editMessage {
  width: 35%;
  right: 0;
  top: 0;
  background: #e6e6e6;
  z-index: 10;
  overflow: scroll;
}

.closeBtn {
  width: 2.5rem;
  height: 2.5rem;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.input-number {
  width: 70px;
}

.text-area {
  min-height: 4rem;
}

.empty-cta-style {
  border: 2px solid red;
}

.input-width {
  width: 6rem;
}
</style>

<template>
  <div class="editMessage position-fixed h-100 px-5 d-flex align-items-center">
    <div class="w-100">
      <b-row class="justify-content-between w-100">
        <b-btn
          @click="closeModal"
          variant="creativeLibrary"
          class="closeBtn d-flex align-items-center justify-content-center"
          ><img src="@/assets/images/pictos/cross.svg" width="12" height="12"
        /></b-btn>
        <h3 class="text-center">Edit banner</h3>
        <span></span>
      </b-row>
      <div>
        <b-form-group
          class="my-4 d-flex align-items-center"
          label-cols="4"
          label-cols-lg="2"
          label-size="Default"
          label="Text"
          label-for="input-default"
          label-align="right"
          label-class="p-0 mr-3"
        >
          <b-form-textarea id="text" name="text" v-model="text" rows="2" class="text-area"></b-form-textarea>
        </b-form-group>
        <b-form-group
          class="my-4 d-flex align-items-center"
          label-cols="4"
          label-cols-lg="2"
          label-size="Default"
          label="Offer"
          label-for="input-default"
          label-align="right"
          label-class="p-0 mr-3"
        >
          <b-form-textarea id="offer" name="offer" v-model="offer" rows="2" class="text-area"></b-form-textarea>
        </b-form-group>
        <b-form-group
          class="my-4 d-flex align-items-center"
          label-cols="4"
          label-cols-lg="2"
          label-size="Default"
          label="Terms"
          label-for="input-default"
          label-align="right"
          label-class="p-0 mr-3"
        >
          <b-form-input id="terms" name="terms" v-model="terms"></b-form-input>
        </b-form-group>
        <b-form-group
          class="my-4 d-flex align-items-center"
          label-cols="4"
          label-cols-lg="2"
          label-size="Default"
          label="CTA"
          label-for="input-default"
          label-align="right"
          label-class="p-0 mr-3"
        >
          <b-form-select id="ctaname" v-model="cta" :options="listctanames"></b-form-select>
        </b-form-group>
        <b-form-group
          class="my-4 d-flex align-items-center"
          label-cols="4"
          label-cols-lg="2"
          label-size="Default"
          label="CTA Style"
          label-for="input-default"
          label-align="right"
          label-class="p-0 mr-3"
        >
          <b-form-select
            id="ctastyle"
            v-model="ctaStyle"
            :options="listctastyles"
            :class="!ctaStyle && 'empty-cta-style'"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          class="my-4 d-flex align-items-center"
          label-cols="4"
          label-cols-lg="2"
          label-size="Default"
          label="Logo position"
          label-for="input-default"
          label-align="right"
          label-class="p-0 mr-3"
        >
          <b-row class="d-flex justify-content-between m-0">
            <b-col sm="8">
              <div class="d-flex w-100 justify-content-center">
                <label for="logoPosition">Translate</label>
              </div>
              <b-row style="gap: 0.5rem">
                <b-input-group class="p-0 input-width" size="default" append="x">
                  <b-form-input
                    id="logoX"
                    name="logoX"
                    type="number"
                    v-model="logoTransform.x"
                    class="input-number"
                    required
                    append="x"
                  ></b-form-input>
                </b-input-group>
                <b-input-group class="p-0 input-width" size="default" append="y">
                  <b-form-input
                    id="logoY"
                    name="logoY"
                    type="number"
                    v-model="logoTransform.y"
                    class="input-number"
                    required
                  ></b-form-input>
                </b-input-group>
              </b-row>
            </b-col>

            <b-col sm="4" class="d-flex flex-column align-items-center m-0 p-0">
              <div class="d-flex w-100 justify-content-center">
                <label for="logoPosition">Scale</label>
              </div>
              <b-input-group class="p-0 input-width" size="default" append="%">
                <b-form-input
                  id="logoScale"
                  name="logoScale"
                  type="number"
                  v-model="logoTransform.scale"
                  class="input-number"
                  required
                ></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
          class="my-4 d-flex align-items-center"
          label-cols="4"
          label-cols-lg="2"
          label-size="Default"
          label="Video position"
          label-for="input-default"
          label-align="right"
          label-class="p-0 mr-3"
          v-if="type === 'square'"
        >
          <b-row class="d-flex justify-content-between m-0">
            <b-col sm="8">
              <div class="d-flex w-100 justify-content-center">
                <label for="videoPosition">Translate</label>
              </div>
              <b-row style="gap: 0.5rem">
                <b-input-group class="p-0 input-width" size="default" append="x">
                  <b-form-input
                    id="videoX"
                    name="videoX"
                    type="number"
                    v-model="videoTransform.x"
                    class="input-number"
                    required
                  ></b-form-input>
                </b-input-group>
                <b-input-group class="p-0 input-width" size="default" append="y">
                  <b-form-input
                    id="videoY"
                    name="videoY"
                    type="number"
                    v-model="videoTransform.y"
                    class="input-number"
                    required
                  ></b-form-input>
                </b-input-group>
              </b-row>
            </b-col>
          </b-row>
        </b-form-group>
        <b-row class="mt-4 justify-content-end">
          <b-btn class="mr-4" type="reset" variant="light" @click="preview">Preview</b-btn>
          <b-btn type="submit" class="border-0 d-flex" variant="creativeLibrary" @click="submit" id="hoverWarning">
            <b-spinner label="Loading..." v-if="loading"></b-spinner>
            <span v-else>Save</span></b-btn
          >
          <b-tooltip target="hoverWarning" triggers="hover"> <b>Warning:</b> this action modifies real data </b-tooltip>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditMessage",
  data() {
    return {
      text: this.getDataFrame("Tagline"),
      offer: this.getDataFrame("Offer"),
      terms: this.getDataFrame("ML"),
      cta: this.getDataFrame("CTA"),
      ctaStyle: this.getDataFrame("CTA Style"),
      logoTransform: {
        x: parseInt(this.getDataDesign("logoTransform").split("translate(")[1].split(",")[0]),
        y: parseInt(this.getDataDesign("logoTransform").split(",")[1].split(")")[0]),
        scale: parseInt(this.getDataDesign("logoTransform").split("scale(")[1].split(")")[0]),
      },
      videoTransform: {
        x: parseInt(this.getDataDesign("videoTransform").split("translate(")[1].split(",")[0]),
        y: parseInt(this.getDataDesign("videoTransform").split(",")[1].split(")")[0]),
      },
      listctanames: [
        ...new Set(
          this.ctaNamesTable
            .filter((market) => market.lang?.includes(this.market))
            .map((cta) => {
              return cta["#Cta list"];
            })
        ),
      ],
      listctastyles: [],
    };
  },
  props: {
    ctaNamesTable: { type: Array, required: false },
    assetsLangTable: { type: Array, required: false },
    assetsDesignTable: { type: Array, required: false },
    size: { type: String, required: false },
    type: { type: String, required: false },
    market: { type: String, required: false },
    message: { type: String, required: false },
    loading: { type: Boolean, required: true },
  },

  computed: {
    ctaNames() {
      return [
        ...new Set(
          this.ctaNamesTable
            .filter((market) => market.lang.includes(this.market))
            .map((cta) => {
              return cta["#Cta list"];
            })
        ),
      ];
    },
    ctaStyles() {
      return [
        ...new Set(
          this.ctaNamesTable
            .filter((el) => el.lang.includes(this.market) && el["#Cta list"] === this.cta)
            .map((cta) => {
              return cta["#Cta style"];
            })
        ),
      ];
    },
    newData() {
      return {
        text: this.text,
        offer: this.offer,
        terms: this.terms,
        cta: this.cta,
        ctaStyle: this.ctaStyle,
        logoTransform: {
          x: parseInt(this.logoTransform.x),
          y: parseInt(this.logoTransform.y),
          scale: this.logoTransform.scale,
        },
        videoTransform: {
          x: parseInt(this.videoTransform.x),
          y: parseInt(this.videoTransform.y),
        },
      };
    },
  },

  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    submit() {
      this.$emit("submit", this.newData);
    },
    preview() {
      this.$emit("preview", this.newData);
    },
    getDataFrame(variable) {
      const findByName = this.assetsLangTable.find(
        (el) =>
          el["#Market"] === this.market && el["#Campaign Naming"] === this.message && el["#Size Category"] === this.size
      );
      if (!findByName) {
        const findByType = this.assetsLangTable.find(
          (el) =>
            el["#Market"] === this.market &&
            el["#Campaign Naming"] === this.message &&
            el["#Size Category"] === this.type
        );
        return findByType[variable];
      } else {
        return findByName[variable];
      }
    },

    getDataDesign(variable) {
      const findByName = this.assetsDesignTable.find(
        (el) => el["#Campaign Naming"] === this.message && el["#Size Category"] === this.size
      );
      if (!findByName) {
        const findByType = this.assetsDesignTable.find(
          (el) => el["#Campaign Naming"] === this.message && el["#Size Category"] === this.type
        );
        return findByType[variable];
      } else {
        return findByName[variable];
      }
    },
    updateData() {
      this.text = this.getDataFrame("Tagline");
      this.offer = this.getDataFrame("Offer");
      this.terms = this.getDataFrame("ML");
      this.cta = this.getDataFrame("CTA");
      this.ctaStyle = this.getDataFrame("CTA Style");
      this.listctanames = this.ctaNames;
      this.listctastyles = this.ctaStyles;
      this.logoTransform = {
        x: parseInt(this.getDataDesign("logoTransform").split("translate(")[1].split(",")[0]),
        y: parseInt(this.getDataDesign("logoTransform").split(",")[1].split(")")[0]),
        scale: parseInt(this.getDataDesign("logoTransform").split("scale(")[1].split(")")[0]),
      };
      this.videoTransform = {
        x: parseInt(this.getDataDesign("videoTransform").split("translate(")[1].split(",")[0]),
        y: parseInt(this.getDataDesign("videoTransform").split(",")[1].split(")")[0]),
      };
    },
  },
  watch: {
    message() {
      this.updateData();
    },
    size() {
      this.updateData();
    },
    cta() {
      this.listctastyles = this.ctaStyles;
      if (!this.listctastyles.includes(this.ctaStyle)) {
        this.ctaStyle = "";
      }
    },
  },
  mounted() {
    this.listctastyles = [
      ...new Set(
        this.ctaNamesTable
          .filter((el) => el["#Cta list"].includes(this.cta))
          .map((cta) => {
            return cta["#Cta style"];
          })
      ),
    ];
  },
};
</script>
