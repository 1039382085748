<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.disabled-card {
  opacity: 0.4;
  pointer-events: none;
}

.gap {
  gap: 4rem;
}
</style>

<template>
  <b-card-group class="main-margins mx-auto w-100 d-flex justify-content-center align-items-center gap" deck>
    <div class="d-flex flex-column gap align-items-center">
      <Card
        :route="getSubMenuUrl(Object.keys(DecisionTreeService.messagesTypes)[0])"
        :image="require('@/assets/images/pictos/picto-affiliation.svg')"
        name="Affiliation"
      />
      <Card
        :route="getSubMenuUrl(Object.keys(DecisionTreeService.messagesTypes)[3])"
        :image="require('@/assets/images/pictos/picto-retargeting.svg')"
        name="Retargeting"
      />
    </div>
    <div class="d-flex flex-column gap align-items-center">
      <Card
        :route="getSubMenuUrl(Object.keys(DecisionTreeService.messagesTypes)[1])"
        :image="require('@/assets/images/pictos/picto-data-provider.svg')"
        name="Data-provider (Sojern)"
      />
      <Card
        :route="getSubMenuUrl(Object.keys(DecisionTreeService.messagesTypes)[2])"
        :image="require('@/assets/images/pictos/picto-acquisition.svg')"
        name="Acquisition"
        class="disabled-card"
      />
    </div>
  </b-card-group>
</template>

<script>
import Card from "@/views/decisionTree/Card.vue";
import DecisionTreeService from "@/services/DecisionTreeService";

export default {
  name: "DecisionTree",
  computed: {
    DecisionTreeService() {
      return DecisionTreeService;
    },
  },
  components: { Card },
  title: "Decision Tree - Disneyland CMP",
  data() {
    return {};
  },
  props: ["user"],
  methods: {
    getSubMenuUrl(strategy) {
      if (strategy) {
        return {
          name: "DecisionTreeStrategy",
          params: {
            strategy: strategy,
          },
        };
      } else {
        return null;
      }
    },
  },
};
</script>
