<template>
  <b-modal ref="adblock" id="adblock" header-bg-variant="danger" centered>
    <template #modal-title><p class="text-white m-0">Adblocker detected</p></template>
    <div class="d-block">
      <p class="my-2">An adblocker is detected. The creative can't be displayed. Please disable it.</p>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="danger" size="m" class="float-right" @click="$bvModal.hide('adblock')"> OK </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import BackEndpoints from "@/router/endpoints/BackEndpoints";
import axios from "axios";

export default {
  name: "Adblock",
  methods: {
    async detectAdblock() {
      try {
        await axios.get(BackEndpoints.adblock);
      } catch (err) {
        this.$refs["adblock"].show();
      }
    },
  },
  async mounted() {
    await this.detectAdblock();
  },
};
</script>
