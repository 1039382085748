export default {
  // undefined in test suites (.env not read)
  frontUrl: process.env.VUE_APP_FRONT_URL,
  toAbsolute(url) {
    return this.frontUrl + url;
  },

  // Use only in test
  withParams(route, params) {
    route = route.replace("/", "");
    for (const key in params) {
      route = route.replace(":" + key, params[key]);
    }
    return route;
  },
  homePage: "/app",
  home: "/app/home",
  login: "/app/login",
  creativeSets: "/app/projectManagement/creativeSet/list",
  creativeSetCreate: "/app/projectManagement/creativeSet/create",
  creativeSetEdit: "/app/projectManagement/creativeSet/:creativeSetId",
  liveEdit: "/app/creativeLibrary/liveEdit/:creativeSetId?",
  externalViewLiveEdit: "/app/external/liveEdit/",
  bulkEdit: "/app/creativeLibrary/bulkEdit/:spreadsheetId",
  decisionTree: "/app/decisionTree/",
  decisionTreeStrategy: "/app/decisionTree/:strategy",
  offersView: "/app/decisionTree/:strategy/:market",
};
