import BackEndpoints from "@/router/endpoints/BackEndpoints";

export default class Media {
  constructor(props) {
    this.id = props.id;
    this.name = props.name;
    this.contentType = props.contentType;
    this.size = props.size;
  }

  get url() {
    return BackEndpoints.toAbsolute(BackEndpoints.showMedia(this.id)) + `?_=${Date.now()}`;
  }

  get originalUrl() {
    return BackEndpoints.toAbsolute(BackEndpoints.originalMedia(this.id)) + `?_=${Date.now()}`;
  }
}
