import axios from "axios";
import Media from "@/domain/Media";
import BackEndpoints from "@/router/endpoints/BackEndpoints";
import ToastService from "@/services/ToastService";
import ErrorUtils from "@/utils/ErrorUtils";

export default {
  async upload(formData){
    console.log("upload", formData);
    try {
      let resp = await axios.post(BackEndpoints.mediaUpload(), formData);
      return new Media(resp.data);
    } catch (error) {
      ToastService.error(
        "Error when loading Media",
        ErrorUtils.getMessage(error)
      );
      throw error;
    }

  },
  async mediaUploadDrive(formData){
    console.log("mediaUploadDrive", formData);
    try {
      let resp = await axios.post(BackEndpoints.mediaUploadDrive(), formData);
      return {buildFileDriveId: resp.data.buildFileDriveId, buildFileDriveName: resp.data.buildFileDriveName};
    } catch (error) {
      ToastService.error(
        "Error when uploading media to Google Drive",
        ErrorUtils.getMessage(error)
      );
      throw error;
    }

  },
  async get(mediaId) {
    try {
      let resp = await axios.get(
        BackEndpoints.media(mediaId)
      );
      return new Media(resp.data);
    } catch (error) {
      ToastService.error(
        "Error when loading Media",
        ErrorUtils.getMessage(error)
      );
      throw error;
    }
  },
};
