import BackEndpoints from "@/router/endpoints/BackEndpoints";
import axios from "axios";
import ToastService from "@/services/ToastService";
import ErrorUtils from "@/utils/ErrorUtils";

export default {
  async send(email) {
    try {
      await axios.post(BackEndpoints.email, email);
      ToastService.success("Success", "The email will be sent shortly.");
    } catch (error) {
      ToastService.error(
        "Error when sending email",
        ErrorUtils.getMessage(error)
      );
      throw error;
    }
  },
};
