<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

#newCreativeSetButton {
  height: 2.5rem;
  width: 6rem;
  top: 1rem;
  border-radius: 0.5rem;
  gap: 0.5rem;

  img {
    width: 1.5rem;
  }
}

.card.creativeSetCard {
  flex: none;
  width: 250px;
  border: none;
  border-radius: 0;

  .card-header {
    width: 100%;
    height: 208.33px;
  }

  .card-body {
    a {
      text-decoration: underline;
    }

    a.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    .links {
      font-size: 13.5px;
      text-decoration: none;

      &_icon {
        width: 20px;
        height: 15px;
        margin-right: 4px;
      }

      &:hover {
        color: $primary;
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
  }
}

.creativeset-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  gap: 50px;
  border-radius: 0;
}

.btn-edit {
  background: $primary;
  width: 26px;
  height: 26px;
  border: 1px solid #fff;
  & img {
    height: 24px;
    width: 24px;
  }
}

.badge {
  height: 26px;
  font-weight: 400;
  width: 63px;
  font-size: 14px;
  cursor: default;
}

.card-title {
  gap: 5px;
}

.card-header {
  padding: 5px;
  border: 1px solid $primary;
  box-sizing: border-box;
  margin: 0;
}

.card .card-header {
  border-bottom: 1px solid $primary;
}

.card-header:first-child {
  border-radius: 2px;
}

.creativeset-name {
  color: $primary;
  font-size: 1.25em;
}

.not-ready {
  top: 0;
  left: 0;
  object-fit: cover;
  background-color: #d9d9d9;
  background-image: url(../assets/images/mickey.svg);
  background-repeat: no-repeat;
  background-size: 94% auto;
  background-position: center 37px;
}
.not-ready .notification {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 85%;
  height: 28%;
  margin-top: 57%;
  font-size: 11px;
}

.search {
  height: 2.5em;
}
.search .input-group-text {
  background-color: transparent;
  width: 2.5em;
  padding: 0.3em;
  background-color: $primary;
  border: 1px solid $primary;
}
.search.hidden .input-group-text {
  background-color: $primary;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.search .search-input,
.search .search-input::placeholder {
  color: $primary;
  transition: all 0.4s linear;
}
.search .search-input::placeholder {
  color: #8a9bae;
}
.search.hidden .search-input {
  width: 0;
  padding: 0;
  flex: 0;
  border-color: transparent;
}
.search-input:focus {
  color: $primary;
  border-color: #ced4da;
  box-shadow: unset;
}
.search-icon.rounded .input-group-text {
  border-radius: 50%;
}

#archived {
  height: 2.5em;
  width: 3em;
  border-radius: 0.5em;
  &.btn-primary:focus,
  &.btn-primary:active {
    background-color: $primary;
  }
  img {
    height: 1.5em;
    width: 1.5em;
  }

  &.unselected,
  &.unselected.btn-primary:focus,
  &.unselected.btn-primary:active {
    background-color: $gray-700;
  }
}
</style>

<template>
  <div class="main-margins mx-auto margin-top-content">
    <div class="d-flex justify-content-between mb-5">
      <b-col sm="3">
        <b-input-group
          size="m"
          class="search"
          @click="(activeSearch = true), setFocus()"
          :class="{ hidden: !activeSearch }"
        >
          <b-input-group-prepend is-text class="h-100 search-icon" :class="{ rounded: !activeSearch }">
            <img src="../assets/images/pictos/search_white.png" class="mw-100 mh-100" />
          </b-input-group-prepend>
          <b-input
            v-model="searchInput"
            type="search"
            placeholder="Search"
            @input="filterCreativeSet"
            class="h-100 search-input"
            ref="input"
          />
        </b-input-group>
      </b-col>

      <b-col class="d-flex flex-column align-items-center">
        <b-tabs active-nav-item-class="text-projectManagement">
          <b-tab title="All" active @click="changeTab('All')"></b-tab>
          <b-tab title="Ready" @click="changeTab('Ready')"></b-tab>
          <b-tab title="Testing" @click="changeTab('Testing')"></b-tab>
          <b-tab title="Draft" @click="changeTab('Draft')"></b-tab>
        </b-tabs>
      </b-col>
      <b-col sm="3" class="d-flex justify-content-end">
        <b-btn
          v-if="state === 'All'"
          id="archived"
          variant="primary"
          class="text-white btn-new shadow d-flex justify-content-center align-items-center p-0"
          :class="{ unselected: !archivedSelected }"
          @click="showArchived"
        >
          <img src="../assets/images/pictos/archived.svg" />
        </b-btn>
        <b-btn
          id="newCreativeSetButton"
          variant="primary"
          class="text-white btn-new shadow d-flex justify-content-center align-items-center p-0 ml-2"
          :to="{ name: 'CreativeSetCreate' }"
        >
          <img src="../assets/images/pictos/plus.svg" />New
        </b-btn>
      </b-col>
    </div>
    <b-card-group
      class="creativeset-container justify-content-center"
      :class="creativeSets.length > 2 && 'justify-content-between'"
    >
      <div class="d-flex justify-content-center" v-for="creativeSet in creativeSets" :key="creativeSet.id">
        <b-card class="creativeSetCard" no-body>
          <b-card-header class="d-flex position-relative">
            <b-card-img
              v-if="creativeSet.isReady && creativeSet.imageReady"
              class="position-absolute w-100 h-100"
              style="top: 0; left: 0; object-fit: contain"
              :src="creativeSet.backupImageUrl"
            ></b-card-img>
            <div v-else class="position-absolute w-100 h-100 p-2 not-ready">
              <b-card-text class="notification mx-auto">
                <p v-if="creativeSet.buildFileDriveId" class="m-0 text-ready">
                  <b-icon icon="exclamation-circle-fill" variant="ready"></b-icon>
                  The buildfile has been loaded successfully
                </p>
                <p v-else class="m-0 text-draft">
                  <b-icon icon="exclamation-circle-fill" variant="draft"></b-icon>
                  No buildfile
                </p>
                <p v-if="creativeSet.configReady" class="m-0 text-ready">
                  <b-icon icon="exclamation-circle-fill" variant="ready"></b-icon>
                  The creative configuration is ready
                </p>
                <p v-else class="m-0 text-draft">
                  <b-icon icon="exclamation-circle-fill" variant="draft"></b-icon>
                  The name or market is not completed
                </p>
                <p v-if="creativeSet.spreadsheetUrl" class="m-0 text-ready">
                  <b-icon icon="exclamation-circle-fill" variant="ready"></b-icon>
                  The data spreadsheet is created
                </p>
                <p v-else class="m-0 text-draft">
                  <b-icon icon="exclamation-circle-fill" variant="draft"></b-icon>
                  The data spreadsheet is not created
                </p>
              </b-card-text>
            </div>

            <b-card-title class="w-100 mb-0 position-relative d-flex align-items-start justify-content-end">
              <b-badge
                :class="getStateClass(creativeSet.state)"
                class="d-flex justify-content-center align-items-center"
              >
                {{ creativeSet.state }}
              </b-badge>
              <b-btn
                size="sm"
                class="bg-none text-white btn-edit d-flex justify-content-center align-items-center"
                variant="outline-none"
                title="Edit"
                :to="{
                  name: 'CreativeSetEdit',
                  params: { creativeSetId: creativeSet.id },
                }"
              >
                <img class="pencil" src="../assets/images/pictos/pencil.svg" />
              </b-btn>
            </b-card-title>
          </b-card-header>
          <b-card-body class="pt-2">
            <b-card-text>
              <h3 class="text-center creativeset-name mb-3">
                {{ creativeSet.name }}
              </h3>
              <b-row>
                <b-col sm="4" class="p-0 d-flex justify-content-center align-items-center">
                  <b-link
                    class="links d-flex align-items-center"
                    :to="{
                      name: 'LiveEditPreselect',
                      params: { creativeSetId: creativeSet.id },
                    }"
                  >
                    <img class="links_icon" src="../assets/images/pictos/view.svg" />
                    View</b-link
                  ></b-col
                >
                <b-col sm="4" class="p-0 d-flex justify-content-center">
                  <b-link
                    class="d-flex align-items-center"
                    :class="{
                      disabled: !creativeSet.spreadsheetUrl,
                      links: true,
                    }"
                    :to="getBulkEditUrl(creativeSet.spreadsheetId)"
                    variant="outline-none"
                    ><img class="links_icon" src="../assets/images/pictos/data.svg" /> Data</b-link
                  >
                </b-col>
                <b-col sm="4" class="p-0 d-flex justify-content-center">
                  <b-link
                    :id="'zipLink_' + creativeSet.id"
                    class="links d-flex align-items-center"
                    :disabled="!creativeSet.zipReady"
                    :href="creativeSet.backupZipUrl"
                  >
                    <img class="links_icon" src="../assets/images/pictos/backup.svg" />
                    Backup</b-link
                  >
                  <b-tooltip :target="'zipLink_' + creativeSet.id" triggers="hover">
                    {{ creativeSet.zipLastModified }}
                  </b-tooltip>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card-body>
        </b-card>
      </div>
    </b-card-group>
    <Adblock />
  </div>
</template>

<script>
import CreativeSetService from "@/services/CreativeSetService";
import axios from "axios";
import CreativeSet from "@/domain/CreativeSet";
import Adblock from "./liveEdit/Adblock.vue";

export default {
  components: { Adblock },
  name: "CreativeSets",
  title: "Project Management - Disneyland CMP",
  data() {
    return {
      allCreativeSets: [],
      creativeSets: [],
      searchInput: "",
      archivedSelected: false,
      notArchivedCreativeSets: [],
      state: "All",
      activeSearch: false,
    };
  },
  methods: {
    setFocus() {
      this.$refs.input.focus();
    },
    async checkZipReady(creativeSet) {
      try {
        let { status, headers } = await axios.head(creativeSet.backupImageUrl);
        console.log("checkZipReady creativeSet", creativeSet, status, headers);
        creativeSet.zipReady = status === 200;
        creativeSet.zipLastModified = headers["last-modified"];
      } catch (e) {
        creativeSet.zipReady = false;
      }
    },
    async checkImageReady(creativeSet) {
      try {
        let { status, headers } = await axios.head(creativeSet.backupImageUrl);
        console.log("checkImageReady creativeSet", creativeSet, status, headers);
        creativeSet.imageReady = status === 200;
      } catch (e) {
        creativeSet.imageReady = false;
      }
    },
    changeTab(state) {
      this.state = state;
      this.filterCreativeSet();
    },
    getStateClass(state) {
      if (state === CreativeSet.stateList.at(-1)) {
        return "bg-light";
      } else {
        return `bg-${state.toLowerCase()}`;
      }
    },
    getBulkEditUrl(spreadsheetId) {
      if (spreadsheetId) {
        return {
          name: "BulkEdit",
          params: {
            spreadsheetId: spreadsheetId,
          },
        };
      } else {
        return null;
      }
    },
    showArchived() {
      this.archivedSelected = !this.archivedSelected;
      this.filterCreativeSet();
    },
    filterCreativeSet() {
      let tempCreativeSets = [...this.allCreativeSets];
      tempCreativeSets = this.allCreativeSets.filter((creativeSet) => {
        return this.archivedSelected ? true : creativeSet.state !== CreativeSet.stateList.at(-1);
      });
      tempCreativeSets = tempCreativeSets.filter((creativeSet) => {
        return this.state === "All" ? true : creativeSet.state === this.state;
      });
      tempCreativeSets = tempCreativeSets.filter((creativeSet) => {
        return creativeSet.name.toLowerCase().includes(this.searchInput);
      });

      this.creativeSets = tempCreativeSets;
    },
  },
  async mounted() {
    this.allCreativeSets = await CreativeSetService.list();

    this.filterCreativeSet();

    for await (let creativeSet of this.creativeSets) {
      creativeSet.zipReady = false;
      creativeSet.imageReady = false;
      await this.checkZipReady(creativeSet);
      await this.checkImageReady(creativeSet);
    }
  },
};
</script>
