import ToastService from "@/services/ToastService";
import ErrorUtils from "@/utils/ErrorUtils";

import gapi from "@/assets/library/gapi-min-3.50";

export default {
  SCENARIO_COL: "#scenario",
  SIZE_COL: "#size",
  INITDATA_COL: "json",
  CTA_SPREADSHEET_ID: "19nsIGmdCUuOv2fwe7zYLsrMjYoyLEXIBzdDFlxbRPMY",
  SHEETSTRATEGY: { affiliation: "Affiliation_", dataProvider: "Sojern_", retargeting: "Retargeting_" },

  async initClient() {
    await new Promise((resolve, reject) => {
      gapi.load("client", {callback: resolve, onerror: reject});
    });

    await gapi.client.init({});

    await gapi.client.load("https://www.googleapis.com/discovery/v1/apis/sheets/v4/rest");
  },
  setToken(oAuthAccessToken) {
    gapi.client.setToken({access_token: oAuthAccessToken});
  },

  async getSheets(ranges, spreadsheetId) {
    try {
      let response = await gapi.client.sheets.spreadsheets.values.batchGet({
        spreadsheetId: spreadsheetId,
        ranges: ranges,
      });
      response = response.result.valueRanges;

      const dataTables = [];

      response.map((sheet, indexSheet) => {
        const data = {
          headers: sheet.values.shift(),
          rows: [],
        };

        sheet.values.forEach((row) => {
          const rowMap = {};
          data.headers.forEach((colHeader, index) => {
            rowMap[colHeader] = row[index];
          });
          data.rows.push(rowMap);
        });

        dataTables[ranges[indexSheet]] = data;
      });

      return dataTables;
    } catch (error) {
      console.log("error", error);
      ToastService.error(
        "Error",
        `We couldn't get the spreadsheet with id :${spreadsheetId}. Error :${ErrorUtils.getMessage(error)}`
      );
      throw error;
    }
  },

  async batchUpdateValues(spreadsheetId, params, dataLang, dataDesign) {
    let ranges = ["Assets_lang", "Assets_design"];
    try {
      let response = await gapi.client.sheets.spreadsheets.values.batchGet({
        spreadsheetId: spreadsheetId,
        ranges: ranges,
      });

      const result = response.result;
      const assets_lang = result.valueRanges[0].values;
      const assets_design = result.valueRanges[1].values;

      //Assets lang
      const rowIndexLang = assets_lang.findIndex(
        (row) => row[0] === params.market && row[1] === params.message && row[2] === params.size
      );

      if (rowIndexLang !== -1) {
        const values = [[dataLang.tagline, dataLang.offer, dataLang.cta, dataLang.ml, dataLang.ctaStyle]];

        const body = {
          data: [
            {
              range: `Assets_lang!D${rowIndexLang + 1}:H${rowIndexLang + 1}`,
              values: values,
            },
          ],
          valueInputOption: "USER_ENTERED",
        };

        await gapi.client.sheets.spreadsheets.values.batchUpdate({
          spreadsheetId: spreadsheetId,
          resource: body,
        });
      } else {
        const values = [
          [
            params.market,
            params.message,
            params.size,
            dataLang.tagline,
            dataLang.offer,
            dataLang.cta,
            dataLang.ml,
            dataLang.ctaStyle,
          ],
        ];

        const request = {
          spreadsheetId: spreadsheetId,
          range: "Assets_lang",
          valueInputOption: "USER_ENTERED",
          insertDataOption: "INSERT_ROWS",
          resource: {
            majorDimension: "ROWS",
            values: values,
          },
        };

        await gapi.client.sheets.spreadsheets.values.append(request);
      }

      //Assets design
      const rowIndexDesign = assets_design.findIndex((row) => row[0] === params.message && row[1] === params.size);
      if (rowIndexDesign !== -1) {
        const values = [
          [
            dataDesign.colorTxt,
            dataDesign.bgCta,
            dataDesign.colorCta,
            dataDesign.colorCopyright,
            dataDesign.logo,
            dataDesign.background,
            dataDesign.logoTransform,
            dataDesign.videoTransform,
          ],
        ];

        const body = {
          data: [
            {
              range: `Assets_design!C${rowIndexDesign + 1}:L${rowIndexDesign + 1}`,
              values: values,
            },
          ],
          valueInputOption: "USER_ENTERED",
        };

        await gapi.client.sheets.spreadsheets.values.batchUpdate({
          spreadsheetId: spreadsheetId,
          resource: body,
        });
      } else {
        const values = [
          [
            params.message,
            params.size,
            dataDesign.colorTxt,
            dataDesign.bgCta,
            dataDesign.colorCta,
            dataDesign.colorCopyright,
            dataDesign.logo,
            dataDesign.background,
            dataDesign.logoTransform,
            dataDesign.videoTransform,
          ],
        ];

        const request = {
          spreadsheetId: spreadsheetId,
          range: "Assets_design",
          valueInputOption: "USER_ENTERED",
          insertDataOption: "INSERT_ROWS",
          resource: {
            majorDimension: "ROWS",
            values: values,
          },
        };

        await gapi.client.sheets.spreadsheets.values.append(request);
      }
    } catch (error) {
      ToastService.error(
        "Error",
        `We couldn't get the spreadsheet with id :${spreadsheetId}. Error :${ErrorUtils.getMessage(error)}`
      );
      return;
    }
  },

  async batchUpdateValuesDecisionTree(spreadsheetId, range, data, strategy) {
    try {
      let response = await gapi.client.sheets.spreadsheets.values.batchGet({
        spreadsheetId: spreadsheetId,
        ranges: [range],
      });

      const result = response.result;
      const assets = result.valueRanges[0].values;

      //Assets lang
      const rowHeaders = assets[0]
      const columnId = rowHeaders.findIndex((column) => column === "#id")

      const rowIndex = assets.findIndex((row) => row[columnId] === data["#id"]);

      const valuesAffiliation = [
        [
          data["#Market"],
          data["#MessageOffer"],
          data["#id"],
          data["MessageOffer"],
          data["Statut"],
          data["ShareOfVoice"],
          data["StartDate"],
          data["EndDate"],
          data["Creative"],
          data["Message"],
          data["Redirection"],
        ],
      ];

      const valuesDataProvider = [
        [
          data["#id"],
          data["Market"],
          data["MessageNumber"],
          data["Statut"],
          data["ShareOfVoice"],
          data["StartDate"],
          data["EndDate"],
          data["Creative"],
          data["Message"],
          data["Redirection"],
        ],
      ];

      const valuesTypes = {affiliation: valuesAffiliation, dataProvider: valuesDataProvider};

      if (rowIndex !== -1) {
        const body = {
          data: [
            {
              range: `${range}!A${rowIndex + 1}:K${rowIndex + 1}`,
              values: valuesTypes[strategy],
            },
          ],
          valueInputOption: "USER_ENTERED",
        };

        await gapi.client.sheets.spreadsheets.values.batchUpdate({
          spreadsheetId: spreadsheetId,
          resource: body,
        });
      } else {
        const request = {
          spreadsheetId: spreadsheetId,
          range: range,
          valueInputOption: "USER_ENTERED",
          insertDataOption: "INSERT_ROWS",
          resource: {
            majorDimension: "ROWS",
            values: valuesTypes[strategy],
          },
        };

        await gapi.client.sheets.spreadsheets.values.append(request);
      }

    } catch (error) {
      ToastService.error(
        "Error",
        `We couldn't get the spreadsheet with id :${spreadsheetId}. Error :${ErrorUtils.getMessage(error)}`
      );
      return;
    }
  },
  async deleteRow(spreadsheetId, range, data) {
    try {
      let response = await gapi.client.sheets.spreadsheets.values.batchGet({
        spreadsheetId: spreadsheetId,
        ranges: [range],
      });

      const result = response.result;
      const assets = result.valueRanges[0].values;

      const rowHeaders = assets[0]
      const columnId = rowHeaders.findIndex((column) => column === "#id")

      const rowIndex = assets.findIndex((row) => row[columnId] === data["#id"]);

      const requestSheetId = {
        spreadsheetId: spreadsheetId,
        ranges: [range],
        includeGridData: false,
      };
      const respSheetId = await gapi.client.sheets.spreadsheets.get(requestSheetId);
      const resultSheetId = respSheetId.result;

      const request = {
        spreadsheetId: spreadsheetId,
        resource: {
          requests: [
            {
              deleteDimension: {
                range: {
                  sheetId: resultSheetId.sheets[0].properties.sheetId,
                  dimension: 'ROWS',
                  startIndex: rowIndex,
                  endIndex: rowIndex + 1,
                },
              },
            },
          ],
        },
      };
      await gapi.client.sheets.spreadsheets.batchUpdate(request);
    } catch (error) {
      ToastService.error(
        "Error",
        `We couldn't get the spreadsheet with id :${spreadsheetId}. Error :${ErrorUtils.getMessage(error)}`
      );
      return;
    }
  },
};
