<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

footer {
  div {
    position: fixed;
    width: 362px;
    height: 254px;
    right: -170px;
    bottom: -150px;
    border-radius: 50%;

    background: $primary;
    transform: rotate(12.63deg);
  }

  img {
    position: fixed;
    bottom: 1.25rem;
    right: 1.25rem;
    height: 2.25rem;
    width: 7.5rem;
  }
}

.archimede {
  width: 90px;
  height: auto;
  margin-bottom: 95px;
}
</style>

<template>
  <footer>
    <div></div>
    <img src="../../assets/images/archimede.png" alt="logo Archimede" v-if="this.$route.name === 'Home'" class="archimede"/>
    <img src="../../assets/images/logo_dlp-white.svg" alt="logo Disney"/>
  </footer>
</template>
<script>
export default {
  name: "FooterLogo",
};
</script>
