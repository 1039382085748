<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.card {
  width: 10rem;
  height: 100%;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    border-color: $decision-tree !important;
    background-color: $primary;

    & .card-text {
      color: $light !important;
    }
  }

  .card-header {
    color: #fff;
    font-weight: 500;
    font-size: 1.4rem;
  }

  .name-market {
    font-weight: 500;
    font-size: 1.2rem;
  }
}

.pointer-none {
  pointer-events: none;
}

</style>
<template>
  <b-card no-body class="my-1 border border-primary p-3 d-flex flex-column justify-content-between cursor" :class="disabled && 'pointer-none'" @click="getData">
    <b-card-text class="mb-3 text-primary text-center name-market">{{ name }}</b-card-text>
    <div style="flex-grow: 1" class="d-flex flex-column justify-content-center">
      <b-card-text v-for="group in Object.entries(groups)" :key="group[0]" class="m-0 w-100 d-flex justify-content-between">
        <span>{{ group[0] }}</span><span>{{ group[1] ? '✅' : '❌' }}</span></b-card-text>
    </div>
  </b-card>
</template>

<script>
export default {
  name: "Market",
  props: {
    name: {type: String, required: true},
    groups: {type: Object, required: true},
    disabled: {type: Boolean, required: false},
  },
  methods: {
    getData() {
      this.$emit("get-data", this.name);
    },
  },
};
</script>
