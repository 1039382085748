export default class Format {
  constructor(props) {
    this.name = props.name;
    this.updateUrl();
    this.type = props.type;
  }

  updateUrl() {
    this.url = this.getUrlFromName();
  }

  getUrlFromName() {
    return `https://adventori.com/creatives/disneyland-paris/${
      this.name
    }/index.html?date=${new Date().getTime()}`;
  }

  get width() {
    return parseInt(this.name.split("x")[0]);
  }

  get height() {
    return parseInt(this.name.split("x")[1]);
  }
}
