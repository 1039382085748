import Vue from "vue";
import VueRouter from "vue-router";
import FrontEndpoints from "@/router/endpoints/FrontEndpoints";
import Login from "@/views/Login";
import NotFound from "@/views/errors/NotFound";
import Home from "@/views/Home";
import HomePage from "@/views/HomePage";
import CreativeSets from "@/views/CreativeSets";
import CreativeSet from "@/views/creativeSets/CreativeSet";
import LiveEdit from "@/views/LiveEdit";
import BulkEdit from "@/views/BulkEdit";
import LiveEditExternal from "@/views/LiveEditExternal.vue";
import DecisionTree from "@/views/DecisionTree.vue";
import SubMenu from "@/views/decisionTree/SubMenu.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: FrontEndpoints.homePage,
  },
  {
    path: FrontEndpoints.homePage,
    name: "HomePage",
    component: HomePage,
    meta: {
      external: true,
    },
  },
  {
    path: FrontEndpoints.home,
    name: "Home",
    component: Home,
    meta: {
      header: true,
      serviceName: "Content Management Platform",
      displayLogo: true,
      fullMain: true,
    },
  },
  {
    path: FrontEndpoints.login,
    name: "Login",
    component: Login,
    meta: {
      header: false,
      external: true,
      displayLogo: false,
    },
  },
  {
    path: FrontEndpoints.creativeSets,
    name: "CreativeSets",
    component: CreativeSets,
    meta: {
      header: true,
      serviceName: "Project Management",
      subServiceName: "Creative set library",
      displayLogo: true,
    },
  },
  {
    path: FrontEndpoints.creativeSetCreate,
    name: "CreativeSetCreate",
    component: CreativeSet,
    meta: {
      header: true,
      serviceName: "Project Management",
      subServiceName: "New creative set",
      displayLogo: true,
    },
  },
  {
    path: FrontEndpoints.creativeSetEdit,
    name: "CreativeSetEdit",
    component: CreativeSet,
    meta: {
      header: true,
      serviceName: "Project Management",
      subServiceName: "Edit creative set",
      displayLogo: true,
    },
  },
  {
    path: FrontEndpoints.externalViewLiveEdit,
    name: "ExternalLiveEdit",
    component: LiveEditExternal,
    meta: {
      header: true,
      serviceName: "Creative Library",
      subServiceName: "Live Edit",
      displayLogo: true,
      external: true,
    },
  },
  {
    path: FrontEndpoints.liveEdit,
    name: "LiveEditPreselect",
    component: LiveEdit,
    meta: {
      header: true,
      serviceName: "Creative Library",
      subServiceName: "Live Edit",
      displayLogo: true,
    },
  },
  {
    path: FrontEndpoints.bulkEdit,
    name: "BulkEdit",
    component: BulkEdit,
    meta: {
      header: true,
      serviceName: "Creative Library",
      subServiceName: "Bulk Edit",
      displayLogo: false,
    },
  },
  {
    path: FrontEndpoints.decisionTree,
    name: "DecisionTree",
    component: DecisionTree,
    meta: {
      header: true,
      serviceName: "Decision Tree",
      subServiceName: "Strategy Choice",
      displayLogo: true,
      fullMain: true,
    },
  },
  {
    path: FrontEndpoints.decisionTreeStrategy,
    name: "DecisionTreeStrategy",
    component: SubMenu,
    meta: {
      header: true,
      serviceName: "Decision Tree",
      displayLogo: true,
      fullMain: true,
    },
  },
  {
    path: FrontEndpoints.offersView,
    name: "OffersView",
    component: SubMenu,
    meta: {
      header: true,
      serviceName: "Decision Tree",
      subServiceName: "Offers view",
      displayLogo: true,
    },
  },

  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: {
      header: false,
      displayLogo: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
