export default {
  // undefined in test suites (.env not read)
  backUrl: process.env.VUE_APP_BACK_URL,
  toAbsolute(url) {
    return this.backUrl + url;
  },

  login: "/api/login",
  oauth: "/oauth/authenticate/google",
  oauthSpreadsheets: "/oauthSpreadsheet/authenticate/google",
  users: "/api/users/",
  creativeSets: "/api/creative-sets/",
  medias: "/api/medias/",
  email: "/api/email/",
  getStrategiesSheet: "/api/decision-tree/getSheet",
  update: "/api/users/updateSpreadsheet",
  readToken: "/external/readToken",
  getExternalSheets: "/external/getSheets",
  updateMessages: "/api/creative-sets/updateMessages",
  getCreativeSetsMessages: "/api/creative-sets/getCreativeSetsMessages",
  creativeSetIndexExternal: "/external/creativeSetIndex",
  adblock: "/ad/ads.js",
  currentUser() {
    return this.users + "currentUser";
  },
  updateAuthUsers() {
    return this.users + "updateAuthUsers";
  },
  oAuthAccessToken() {
    return this.users + "oAuthAccessToken";
  },
  creativeSet(creativeSetId) {
    return this.creativeSets + creativeSetId;
  },
  shareUrl() {
    return this.creativeSets + "shareUrl";
  },
  backupFolder(spreadsheetId) {
    return `/generated-kits/${spreadsheetId}/`;
  },
  backupZip(spreadsheetId) {
    return this.backupFolder(spreadsheetId) + "DLP.zip";
  },
  backupImage(spreadsheetId) {
    return this.backupFolder(spreadsheetId) + "300x250.jpg";
  },
  media(mediaId) {
    return this.medias + mediaId;
  },
  showMedia(mediaId) {
    return this.medias + mediaId + "/showMedia";
  },
  originalMedia(mediaId) {
    return this.medias + mediaId + "/original";
  },
  mediaUpload() {
    return this.medias + "upload";
  },
  mediaUploadDrive() {
    return this.medias + "uploadDrive";
  },
  mediaDownloadDrive(id) {
    return this.medias + "downloadDrive/" + id;
  },
  withParams(route, params) {
    route += "?";
    for (const key in params) {
      route += key + "=" + params[key] + "&";
    }
    return route;
  },
};
