<style lang="scss">
@import "@/assets/scss/_variables.scss";

.shareModal {
  background: white;
  top: -1rem;
  right: 0;
  width: 35vw;
  border: 2px solid $creative-library;
  z-index: 3;

  &_radioContainer {
    &_input {
      width: 0 !important;
    }

    &_radio {
      background: #dbf6db;
      border-radius: 1.5rem;
    }
  }
}

.active-radio {
  background: $creative-library;
}

.closeBtn {
  width: 4rem;
  height: 4rem;
  background: $creative-library;
  border: none;
  border-radius: 0 !important;
}

.btn-copy {
  width: fit-content;
}
</style>

<template>
  <div class="shareModal border-radius m-0 position-absolute">
    <b-btn
      @click="closeModal"
      variant="creativeLibrary"
      class="closeBtn d-flex align-items-center justify-content-center"
      ><img src="@/assets/images/pictos/cross.svg" width="24" height="24"
    /></b-btn>
    <b-form-group
      v-slot="{ ariaDescribedby }"
      class="shareModal_radioContainer d-flex flex-column pt-5 px-5"
    >
      <b-row
        class="shareModal_radioContainer_radio p-4 mb-3"
        :class="shareMode === 'All' && 'active-radio'"
        @click="shareMode !== 'All' && switchRadio('All')"
      >
        <b-form-radio
          v-model="shareMode"
          :aria-describedby="ariaDescribedby"
          value="All"
          >All Access</b-form-radio
        >
      </b-row>
      <b-row
        @click="shareMode !== 'Market' && switchRadio('Market')"
        class="shareModal_radioContainer_radio p-4 mb-3"
        :class="shareMode === 'Market' && 'active-radio'"
      >
        <b-col>
          <b-form-radio
            v-model="shareMode"
            :aria-describedby="ariaDescribedby"
            value="Market"
            class="mb-2"
            >Block by Market</b-form-radio
          >
          <p
            v-if="!creativeSetId && shareMode === 'Market'"
            class="text-danger"
          >
            Select a creative set
          </p>
          <b-form-group>
            <b-form-select
              v-model="selectedMarketOnly"
              @change="updateShareLink"
              :options="marketOptions"
              id="selectedMarketOnly"
              class="selector d-flex flex-column mw-25 mx-auto"
              :disabled="!creativeSetId || loadingMarket"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        class="shareModal_radioContainer_radio p-4 mb-3"
        :class="shareMode === 'MarketAndCreativeSet' && 'active-radio'"
        @click="
          shareMode !== 'MarketAndCreativeSet' &&
            switchRadio('MarketAndCreativeSet')
        "
      >
        <b-col>
          <b-form-radio
            v-model="shareMode"
            :aria-describedby="ariaDescribedby"
            value="MarketAndCreativeSet"
            class="mb-2"
            >Block by Market and CreativeSet</b-form-radio
          >
          <b-form-group>
            <b-form-select
              v-model="selectedCreativeSetMC"
              @change="
                selectedCreativeSetMC !== creativeSetId
                  ? readCreativeSetSheet()
                  : (marketOptionsMC = marketOptions)
              "
              :options="creativesetsOptions"
              id="selectedCreativeSetMC"
              class="selector d-flex flex-column mw-25 mx-auto"
            ></b-form-select>
          </b-form-group>
          <b-form-group>
            <b-form-select
              v-model="selectedMarketMC"
              @change="updateShareLink"
              :options="marketOptionsMC"
              id="selectedMarketMC"
              class="selector d-flex flex-column mw-25 mx-auto"
              :disabled="!selectedCreativeSetMC || loadingMarketMC"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="d-flex gap-0">
        <b-form-input
          readonly
          v-model="shareLink"
          class="shareModal_radioContainer_input mr-2 flex-grow-1"
          type="text"
        ></b-form-input>
        <b-btn variant="creativeLibrary" @click="copyLink" class="btn-copy"
          >Copy</b-btn
        >
      </b-row>
    </b-form-group>
  </div>
</template>

<script>
import SpreadsheetService from "@/services/SpreadsheetService";
import ToastService from "@/services/ToastService";
import ErrorUtils from "@/utils/ErrorUtils";

export default {
  name: "ShareModal",
  data() {
    return {
      shareMode: "All",
      selectedMarketOnly: this.selectedMarketProp,
      selectedMarketMC: this.selectedMarketProp,
      selectedCreativeSetMC: this.creativeSetId,
      loadingMarketMC: false,
      marketOptionsMC: this.marketOptions || [
        { value: null, text: "Select a market" },
      ],
      spreadsheet: [],
    };
  },
  props: {
    shareLink: { type: String, required: true },
    marketOptions: { type: Array, required: true },
    creativeSets: { type: Array, required: true },
    creativesetsOptions: { type: Array, required: true },
    creativeSetId: { type: String, required: false },
    user: { type: Object, required: true },
    loadingMarket: { type: Boolean, required: true },
    selectedMarketProp: { type: String, required: false },
  },
  computed: {
    dataEmit() {
      return {
        shareMode: this.shareMode,
        selectedMarketOnly: this.selectedMarketOnly,
        selectedMarketMC: this.selectedMarketMC,
        selectedCreativeSetMC: this.selectedCreativeSetMC,
      };
    },
    selectedCreativeSet() {
      return this.creativeSets.find((creativeSet) => {
        return creativeSet.id === this.selectedCreativeSetMC;
      });
    },
  },
  watch: {
    creativeSetId() {
      this.selectedMarketOnly = null;
    },
  },
  methods: {
    updateShareLink() {
      this.$emit("update-share-link", this.dataEmit);
    },

    closeModal() {
      this.$emit("close-modal");
    },
    copyLink() {
      navigator.clipboard.writeText(this.shareLink);
    },
    switchRadio(radio) {
      this.shareMode = radio;
      this.updateShareLink();
    },
    async readCreativeSetSheet() {
      this.marketOptionsMC = [{ value: null, text: "Select a market" }];
      this.selectedMarketMC = null;
      this.loadingMarketMC = true;
      this.initDataMap = {};
      SpreadsheetService.setToken(this.user.oAuthAccessToken);

      this.spreadsheet = await SpreadsheetService.getSheets(
        ["scenarios", "Assets_lang", "Assets_design"],
        this.selectedCreativeSet.spreadsheetId
      );

      try {
        if (
          !this.spreadsheet.scenarios.headers.includes(
            SpreadsheetService.SCENARIO_COL
          )
        ) {
          throw Error("No column #scenario found.");
        }
        if (
          !this.spreadsheet.scenarios.headers.includes(
            SpreadsheetService.SIZE_COL
          )
        ) {
          throw Error("No column #size found.");
        }

        if (
          !this.spreadsheet.scenarios.headers.includes(
            SpreadsheetService.INITDATA_COL
          )
        ) {
          throw Error("No column json found.");
        }
      } catch (error) {
        ToastService.error(
          "Error",
          `Error parsing spreadsheet headers :${ErrorUtils.getMessage(error)}`
        );
      }

      this.spreadsheet.scenarios.rows.forEach((row) => {
        const scenario = row[SpreadsheetService.SCENARIO_COL];
        const market = scenario.split("*")[0];

        if (!this.marketOptionsMC.find((element) => element.value === market)) {
          this.marketOptionsMC.push({
            value: market,
            text: market,
          });
        }
      });

      this.loadingMarketMC = false;
    },
  },
  mounted() {
    this.updateShareLink();
  },
};
</script>
