<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.card-deck {
  .card {
    margin: 0;
    padding: 0;
    overflow: hidden;
    border-width: 0;
    justify-self: center;
    box-shadow: 0px 2px 15px -5px rgba(0, 0, 0, 0.25);

    &:hover{
      border: $decision-tree solid 1px;
    }
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin: 0;
      padding: 0;

      .card-image{
        height: 5rem;
        width: 5rem;
        background-color: rgb(234, 117, 110, 0.7);
      }
      .card-text {
        font-size: 1.3rem;
      }
    }
  }
}

.forward {
  width: 50px;
  height: 50px;

  &_icon {
    filter: invert(80%) sepia(33%) saturate(6207%) hue-rotate(314deg)
      brightness(97%) contrast(88%);
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cardSize {
  width: 300px;
  height: 200px;
  overflow: hidden;
}
</style>
<template>
  <b-link class="text-decoration-none" :to="route">
    <b-card class="cardSize">
      <img class="card-image rounded-circle p-2 bg-opacity-50" :src="image">
      
      <b-card-text class="m-0 text-primary text-center font-weight-bold" v-html="name" />
    </b-card>
  </b-link>
</template>

<script>
export default {
  name: "Card",
  props: {
    route: { type: Object, required: true },
    name: { type: String, required: true },
    image: { type: String, required: true }
  },
};
</script>
