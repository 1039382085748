<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.card {
  .breadcrumb{
    border-top-right-radius: 200px !important;
    border-bottom-right-radius: 200px !important;
  }
  &-body {
    padding: 0;
  }
}

.icon {
  margin-top: -1rem;
  width: 2rem;
  height: 2rem;
  -webkit-mask-size: 75%;
  mask-size: 75%;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
  cursor: pointer;
  -webkit-mask-image: url(../../assets/images/pictos/settings.svg);
  mask-image: url(../../assets/images/pictos/settings.svg);
  background-color: $decision-tree;
}
.icon:hover{
  background-color: $primary;
}

.separator{
  height: 2px;
  width: 75%;
  border-bottom: solid 2px $primary;
}
.calendar{
  height: 1.5rem;
  width: 1.5rem;
}
.table{
  border-collapse: collapse;
  font-size: 1rem;
}

tr th:first-child, tr td:first-child{
  width: 40%;
}
.table-header{
  border-collapse: collapse;
}
.live .table tbody td:nth-child(1){
  background-color: rgb(105, 202, 109, 0.2)
}
.future .table tbody td:nth-child(1){
  background-color: $light;
}
</style>
<template>
  <b-card class="border border-decisionTree p-0 rounded">
    <b-row class="m-0 justify-content-between py-4 pr-2">
      <div class="mb-1 breadcrumb border-0 bg-primary text-light py-2 px-4" style="font-size: 1.2rem">
        {{ offer[0] }}
      </div>
      <p v-if="Object.entries(dataOffer.live).length <= 0 && Object.entries(dataOffer.comingNext).length <= 0" class="m-0 d-flex justify-content-center align-items-center">No live or coming messages</p>
      <div class="icon rounded p-2" @click="openEdit"></div>
    </b-row>
    <b-row class="m-0 flex-column px-2">
      <b-card v-for="row in Object.entries(dataOffer.live)" :key="row['#id']" class="live border-ready p-1 my-2">
        <div class="dates w-75 mx-auto d-flex justify-content-around pt-2">
          <div>
            <img class="calendar" src="@/assets/images/pictos/start-date.svg">
            <p class="date d-inline-block pl-2"> {{ row[0].split(">")[0] }}</p><p class="date d-inline-block pl-2"></p>
          </div>
          <div>
            <img class="calendar" src="@/assets/images/pictos/end-date.svg">
            <p class="date d-inline-block pl-2">{{ row[0].split(">")[1] }}</p>
          </div>
        </div>
        <div class="separator mx-auto"></div>
        <div class="creatives-continer">
          <table class="table w-75 mx-auto">
            <thead class="table-header">
              <tr class="">
                <th>Creative set</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="message in row[1]" :key="message['Message']">
                <td>{{ message["Creative"] }}</td>
                <td>{{ message["Message"] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
      <b-card v-for="row in Object.entries(dataOffer.comingNext)" :key="row['#id']" class="future p-1 my-2">
        <div class="dates w-75 mx-auto d-flex justify-content-around pt-2">
          <div>
            <img class="calendar" src="@/assets/images/pictos/start-date.svg">
            <p class="date d-inline-block pl-2"> {{ row[0].split(">")[0] }}</p><p class="date d-inline-block pl-2"></p>
          </div>
          <div>
            <img class="calendar" src="@/assets/images/pictos/end-date.svg">
            <p class="date d-inline-block pl-2">{{ row[0].split(">")[1] }}</p>
          </div>
        </div>
        <div class="separator mx-auto"></div>
        <div class="creatives-continer">
          <table class="table w-75 mx-auto">
            <thead class="table-header">
              <tr class="">
                <th>Creative</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="message in row[1]" :key="message['Message']">
                <td>{{ message["Creative"] }}</td>
                <td>{{ message["Message"] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "Offer",
  props: {
    offer: { type: Array, required: true },
  },
  computed: {
    dataOffer() {
      return this.offer[1];
    }
  },
  methods: {
    openEdit() {
      this.$emit("open-edit", this.offer);
      console.log("edit open");
    },
  },
};
</script>
