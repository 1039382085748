<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

ol.breadcrumb {
  border-top-right-radius: 200px !important;
  border-bottom-right-radius: 200px !important;
}

.card:nth-child(2n + 0) {
  float: right;
}

.breadcrump-container {
  height: 3rem;
}

.breadcrumb > li + li:before {
  display: none;
}

.breadcrumb li:first-child {
  letter-spacing: 2px;
}

.btn.back-btn:hover {
  background-color: $primary;
  color: white;
}

.breadcrumb-item.active {
  border-radius: 200px;
  color: $primary;
}

.card-group {
  display: block;
}

.offer {
  display: block;
  width: 48% !important;
  float: left;
  margin: 1% 1% !important;

  &:nth-child(2n + 0) {
    float: right;
  }

  .breadcrumb {
    border-top-right-radius: 200px !important;
    border-bottom-right-radius: 200px !important;
  }

  & > .card-body {
    padding: 0;
  }
}

.card-group > .card.inactive {
  background: $gray-200;
  border-color: gray;
  border-radius: 0.25rem;
}

.icon {
  margin-top: -1rem;
  width: 2rem;
  height: 2rem;
  -webkit-mask-size: 75%;
  mask-size: 75%;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
  cursor: pointer;
  -webkit-mask-image: url(../../assets/images/pictos/settings.svg);
  mask-image: url(../../assets/images/pictos/settings.svg);
  background-color: white;
}
.icon:hover {
  background-color: $primary;
}

.default {
  background-color: rgb(234, 117, 110, 0.7);
}
</style>

<template>
  <div class="margin-top-content w-100">
    <b-row class="m-0 p-0 mb-4 breadcrump-container">
      <b-btn
        @click="goBack"
        variant="outline-primary"
        class="mb-3 mr-2 h-100 font-weight-normal text-uppercase back-btn"
        >&lt; Back
      </b-btn>
      <ol class="breadcrumb bg-primary shadow-lg mb-5 h-100">
        <li
          class="h-100 breadcrumb-item font-weight-bold text-light text-uppercase d-flex justify-content-center align-items-center"
        >
          {{ convertToKebabCase(strategy)
          }}<img class="ml-3" src="https://img.icons8.com/offices/100/000000/double-right.png" width="15" height="15" />
        </li>
        <li
          class="h-100 breadcrumb-item font-weight-bold bg-light active ml-3 px-2 py-1 text-center d-flex justify-content-center align-items-center"
        >
          {{ market }}
        </li>
      </ol>
    </b-row>

    <b-card-group class="offers-container mb-4">
      <offer
        v-show="!editDisplay && !editDefaultDisplay"
        v-for="offer in Object.entries(dataActive)"
        :offer="offer"
        :key="offer[0]"
        @open-edit="openEdit"
        class="offer"
      />

      <b-card
        v-show="!editDisplay && !editDefaultDisplay"
        class="offer border border-primary p-0 inactive"
        v-for="(offer, index) in strategy === Object.keys(DecisionTreeService.messagesTypes)[1] && dataInactive"
        :key="index"
      >
        <b-row class="m-0 justify-content-between py-4 pr-2">
          <div class="mb-1 breadcrumb border-0 bg-primary text-light py-2 px-4" style="font-size: 1.2rem">
            {{ offer }}
          </div>
          <p class="m-0 d-flex justify-content-center align-items-center">{{ offer }} is inactive</p>
          <span></span>
        </b-row>
      </b-card>
    </b-card-group>
    <b-card v-show="!editDefaultDisplay && !editDisplay" class="offer border border-primary p-0">
      <b-row class="m-0 justify-content-between py-4 pr-2 default">
        <div class="mb-1 breadcrumb border-0 bg-primary text-light py-2 px-4" style="font-size: 1.2rem">Default</div>
        <div class="d-flex flex-grow-1 px-2 justify-content-around align-items-center">
          <span>{{ this.defaultMessage.Creative }}</span
          ><span>{{ this.defaultMessage.Message }}</span>
        </div>
        <div class="icon rounded p-2" @click="openDefaultEdit"></div>
        <span></span>
      </b-row>
    </b-card>
    <edit-offer
      v-if="editDisplay"
      @close-edit="closeEdit"
      :data-props="editData"
      :creative-sets="creativeSets"
      :messages="messages"
      :max-id-props="maxId"
      @submit="submit"
      :is-loading="isLoading"
      :strategy="strategy"
    />
    <edit-default-offer
      v-if="editDefaultDisplay"
      @close-edit="closeDefaultEdit"
      :data-props="defaultMessage"
      :creative-sets="creativeSets"
      :messages="messages"
      @submit="submit"
      :is-loading="isLoading"
      :strategy="strategy"
    />
  </div>
</template>

<script>
import FrontEndpoints from "@/router/endpoints/FrontEndpoints";
import DecisionTreeService from "@/services/DecisionTreeService";
import Offer from "@/views/decisionTree/Offer.vue";
import EditOffer from "@/views/decisionTree/EditOffer.vue";
import EditDefaultOffer from "@/views/decisionTree/EditDefaultOffer.vue";
import SpreadsheetService from "@/services/SpreadsheetService";
import ToastService from "@/services/ToastService";
import ErrorUtils from "@/utils/ErrorUtils";
import EmailService from "@/services/EmailService";

export default {
  name: "OffersView",
  components: { EditOffer, Offer, EditDefaultOffer },
  title: "Decision Tree - Disneyland CMP",
  data() {
    return {
      strategy: this.$route.params.strategy,
      market: this.marketProp,
      data: [],
      dataInactive: new Set([]),
      dataActive: [],
      editDisplay: false,
      editDefaultDisplay: false,
      editData: [],
      messagesTypes: [{ affiliation: "MessageOffer" }, { dataProvider: "MessageNumber" }],
      isLoading: false,
    };
  },
  props: {
    dataPropActive: { type: Array, required: false },
    dataPropInactive: { type: Array, required: false },
    defaultMessage: { type: Object, required: false },
    dataSettings: { type: Array, required: false },
    marketProp: { type: String, required: true },
    spreadsheetId: { type: String, required: true },
    maxId: { type: Number, required: true },
  },
  computed: {
    DecisionTreeService() {
      return DecisionTreeService;
    },
    creativeSets() {
      const creativeSets = this.dataSettings.map((data) => {
        return { value: data.creativeSet.name, text: data.creativeSet.name };
      });

      creativeSets.unshift({ value: "Empty", text: "Choose a Creative Set" });
      return creativeSets;
    },
    messages() {
      const messages = {};
      this.dataSettings.forEach((data) => {
        if (!messages[data.creativeSet.name]) {
          messages[data.creativeSet.name] = [];
        }

        data.messages.forEach((message) => {
          messages[data.creativeSet.name].push({ value: message.name, text: message.name });
        });

        messages[data.creativeSet.name].unshift({ value: "Empty", text: "Choose a Message" });
      });

      messages["Empty"] = [{ value: "Empty", text: "Choose a Message" }];

      return messages;
    },
  },
  methods: {
    updateUrl() {
      if (!Object.keys(DecisionTreeService.messagesTypes).includes(this.$route.params.strategy)) {
        return this.$router.push(FrontEndpoints.decisionTree);
      }
    },
    goBack() {
      this.$emit("go-back");
    },

    filterDataActive(selectedMessageType) {
      const newData = {};
      const strategy = this.messagesTypes.find((strat) => strat[selectedMessageType]);

      this.data.forEach((row) => {
        const messageType = row[strategy[selectedMessageType]];
        if (messageType === "Default") return;

        if (!newData[messageType]) {
          newData[messageType] = {
            live: {},
            comingNext: {},
            passed: {},
          };
        }

        const startDateExpired = DecisionTreeService.dateIsNowOrBefore(row["StartDate"]);
        const endDateNotExpired = DecisionTreeService.dateIsNowOrAfter(row["EndDate"]);
        const keyDates = row["StartDate"] + " > " + row["EndDate"];

        if (startDateExpired) {
          if (endDateNotExpired) {
            if (!newData[messageType].live[keyDates]) {
              newData[messageType].live[keyDates] = [];
            }
            newData[messageType].live[keyDates].push(row);
          } else {
            if (!newData[messageType].passed[keyDates]) {
              newData[messageType].passed[keyDates] = [];
            }
            newData[messageType].passed[keyDates].push(row);
          }
        } else if (endDateNotExpired) {
          if (!newData[messageType].comingNext[keyDates]) {
            newData[messageType].comingNext[keyDates] = [];
          }
          newData[messageType].comingNext[keyDates].push(row);
        }
      });

      return newData;
    },

    filterDataInactive(selectedMessageType) {
      const strategy = this.messagesTypes.find((strat) => strat[selectedMessageType]);

      this.dataPropInactive.forEach((row) => {
        const messageType = row[strategy[selectedMessageType]];
        if (
          !Object.entries(this.dataActive).some((row2) => {
            row2["messageType"] === messageType;
          })
        ) {
          this.dataInactive.add(messageType);
        }
      });
    },

    convertToKebabCase(str) {
      return str

        .split("")
        .map((letter, idx) => {
          return letter.toUpperCase() === letter ? `${idx !== 0 ? "-" : ""}${letter.toLowerCase()}` : letter;
        })
        .join("");
    },

    openEdit(data) {
      this.editDisplay = true;
      this.editData = data;
    },
    openDefaultEdit() {
      this.editDefaultDisplay = true;
    },
    closeEdit() {
      this.editDisplay = false;
    },
    closeDefaultEdit() {
      this.editDefaultDisplay = false;
    },
    filterData() {
      this.data = this.dataPropActive;
      this.dataActive = this.filterDataActive(this.strategy);
      this.filterDataInactive(this.strategy);
    },
    async submit(changedRows, deletedRows, email) {
      this.isLoading = true;

      if (changedRows.length > 0) {
        try {
          for (const row of changedRows) {
            await SpreadsheetService.batchUpdateValuesDecisionTree(
              this.spreadsheetId,
              `${SpreadsheetService.SHEETSTRATEGY[this.strategy]}${this.market}`,
              row,
              this.strategy
            );
          }

          ToastService.success("Success", `The spreadsheet has been updated`);
        } catch (error) {
          ToastService.error("Error", `Error on save :${ErrorUtils.getMessage(error)}`);
        }
      }
      if (deletedRows.length > 0) {
        try {
          for (const row of deletedRows) {
            await SpreadsheetService.deleteRow(
              this.spreadsheetId,
              `${SpreadsheetService.SHEETSTRATEGY[this.strategy]}${this.market}`,
              row,
            );
          }
          ToastService.success("Success", `The spreadsheet has been updated`);
        } catch (error) {
          ToastService.error("Error", `Error on save :${ErrorUtils.getMessage(error)}`);
        }
      }

      if (changedRows.length || deletedRows.length) {
        await EmailService.send(email);
        this.$emit('refresh')
      }

      this.isLoading = false;
      this.editDisplay = false;
    },
  },
  async mounted() {
    this.updateUrl();
    this.filterData();
  },
};
</script>
