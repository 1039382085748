<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

#fieldset {
  width: 100%;
  top: 0;
  padding-top: 0.5rem;
  z-index: 1;
  background: $white;
}

.selector {
  width: fit-content;
  margin-top: -0.5rem;
}

.multiformat-checkbox {
  display: inline;
}

.displayContainer {
  gap: 2rem;
  padding: 1rem;
}

.carouselContainer {
  height: fit-content;
  gap: 1rem;
}

.carouselControls {
  gap: 8px;
  width: 11rem;
}

.selectorFormat {
  width: 7.5rem;
}

.carouselArrow {
  max-width: 1rem;
  cursor: pointer;
}

.replayButton {
  background-color: $creative-library;
  color: white;

  &:focus {
    background-color: $creative-library;
    color: white;
  }
}
</style>

<template>
  <div class="margin-top-content">
    <b-form-group id="fieldset" :class="multiformatsCheckboxStatus && 'position-sticky'">
      <div class="d-flex align-items-center justify-content-around gap-3">
        <b-form-group>
          <label for="creativeSetSelect">Creative Set</label>
          <b-form-select
            id="creativeSetSelect"
            class="selector d-flex flex-column"
            v-model="selectedCreativeSetId"
            :options="creativeSetOptions"
            @change="changeCreativeSet"
            :disabled="creativeSetDisabled"
          ></b-form-select>
        </b-form-group>

        <b-row class="align-items-center">
          <b-form-group>
            <label for="selectMarket">Market</label>
            <b-form-select
              id="selectMarket"
              class="selector d-flex flex-column mw-25 mx-auto"
              v-model="selectedMarket"
              :options="marketOptions"
              :disabled="!selectedCreativeSet || loadingMarket || marketDisabled"
              @change="changeMarket"
            ></b-form-select>
          </b-form-group>
          <b-spinner class="ml-2" label="Loading..." v-if="loadingMarket"></b-spinner>
        </b-row>

        <b-row class="align-items-center">
          <b-form-group>
            <label for="selectMessage">Message</label>
            <b-form-select
              id="selectMessage"
              class="selector d-flex flex-column mw-25 mx-auto"
              v-model="selectedMessage"
              :options="messagesOptions"
              :disabled="!selectedCreativeSet || loadingMessage"
              @change="changeMessage"
            ></b-form-select>
          </b-form-group>
          <b-spinner class="ml-2" label="Loading..." v-if="loadingMessage && marketDisabled"></b-spinner>
        </b-row>

        <b-form-group class="mt-4">
          <b-form-checkbox
            id="multiformat-checkbox"
            class="multiformat-checkbox"
            v-model="multiformatsCheckboxStatus"
            name="multiformat-checkbox"
            :value="true"
            :unchecked-value="false"
            :disabled="!selectedMarket || !selectedMessage"
          ></b-form-checkbox>
          <label for="multiformat-checkbox" class="ml-2">Multi-format</label>
        </b-form-group>

        <div class="d-flex justify-content-start align-items-center">
          <b-btn
            class="h-50 border-0 replayButton"
            v-if="selectedMarket && selectedMessage && multiformatsCheckboxStatus === false"
            @click="replayCreative"
            >Replay</b-btn
          >
          <b-btn
            class="h-50 border-0 replayButton"
            v-if="selectedMarket && selectedMessage && multiformatsCheckboxStatus"
            @click="replayCreatives"
            >Replay All</b-btn
          >
        </div>
      </div>
    </b-form-group>
    <div
      class="creativeContainer d-flex align-items-center flex-column"
      v-if="selectedCreativeSet && selectedMarket && selectedMessage"
    >
      <div class="carouselControls d-flex justify-content-center mb-3" v-if="!multiformatsCheckboxStatus">
        <img class="carouselArrow" src="../assets/images/arrow.svg" @click="carouselPrev()" />
        <b-form-select
          class="selectorFormat"
          v-model="selectedFormat"
          :options="formatOptions"
          @change="updateSelectedFormat"
        ></b-form-select>
        <img
          class="carouselArrow"
          style="transform: rotate(180deg)"
          src="../assets/images/arrow.svg"
          @click="carouselNext()"
        />
      </div>
      <div
        class="displayContainer d-flex flex-wrap justify-content-center align-items-end"
        v-if="multiformatsCheckboxStatus"
      >
        <div class="display" v-for="format in formats" :key="format.name">
          <BannerPreview
            id="preview"
            :name="format.name"
            :type="format.type"
            :height="format.height"
            :width="format.width"
            :url="format.url"
            :init-data="getInitData(format.name)"
            :multi-format="multiformatsCheckboxStatus"
            ref="creatives"
            @update-url="reloadIframe"
            @open-modal="editMessage"
            :has-edit="false"
          />
        </div>
      </div>
      <div class="carouselContainer d-flex flex-column align-items-center w-100" v-else>
        <div class="flex-grow-1 d-flex flex-column justify-content-center">
          <BannerPreview
            :name="formats[selectedFormat].name"
            :type="formats[selectedFormat].type"
            :height="formats[selectedFormat].height"
            :width="formats[selectedFormat].width"
            :url="formats[selectedFormat].url"
            :init-data="getInitData(formats[selectedFormat].name)"
            :multi-format="multiformatsCheckboxStatus"
            @update-url="reloadIframe"
            @open-modal="editMessage"
            :has-edit="false"
          />
        </div>
      </div>
    </div>
    <Adblock />
  </div>
</template>

<script>
import CreativeSet from "@/domain/CreativeSet";
import BackEndpoints from "@/router/endpoints/BackEndpoints";
import CreativeSetService from "@/services/CreativeSetService";
import ExternalSpreadsheetService from "@/services/ExternalSpreadsheetService";
import SpreadsheetService from "@/services/SpreadsheetService";
import ToastService from "@/services/ToastService";
import ErrorUtils from "@/utils/ErrorUtils";
import BannerPreview from "@/views/liveEdit/BannerPreview";
import Adblock from "./liveEdit/Adblock.vue";

export default {
  name: "LiveEditExternal",
  title: "Creative Library - Disneyland CMP",
  props: ["user"],
  data() {
    return {
      loadingSave: false,
      loadingMarket: false,
      loadingMessage: false,
      creativeSetDisabled: false,
      marketDisabled: false,
      formats: CreativeSet.formatList,
      lockedMarket: null,
      lockedCreativeSet: null,
      initDataMap: {},

      multiformatsCheckboxStatus: false,
      creativeSets: [],
      selectedCreativeSetId: this.creativeSetId || null,
      creativeSetOptions: [{ value: null, text: "Select a creative set" }],

      selectedMarket: null,

      marketOptions: [{ value: null, text: "Select a market" }],

      selectedMessage: null,
      messagesOptions: [{ value: null, text: "Select a message" }],

      selectedFormat: 0,
      formatOptions: [],

      currentFormat: {
        size: "",
        type: "",
      },

      spreadsheet: [],
      ctaSpreadsheet: [],
    };
  },

  components: {
    BannerPreview,
    Adblock,
  },
  computed: {
    token() {
      return this.$route.query.token;
    },
    creativeSetId() {
      return this.$route.query.creativeSetId;
    },
    selectedCreativeSet() {
      return this.creativeSets.find((creativeSet) => {
        return creativeSet.id === this.selectedCreativeSetId;
      });
    },

    updateSpreadsheet() {
      return BackEndpoints.update;
    },
  },
  methods: {
    getInitData(size) {
      const initData = this.initDataMap[this.selectedMarket + this.selectedMessage + size];
      return JSON.stringify(initData);
    },

    async changeCreativeSet() {
      this.refreshUrl();
      this.messagesOptions = [{ value: null, text: "Select a message" }];
      if (this.lockedMarket) {
        await this.readCreativeSetSheet();
        await this.getMessages();
      } else {
        this.marketOptions = [{ value: null, text: "Select a market" }];
        await this.readCreativeSetSheet();
        this.selectedMarket = null;
      }
      this.selectedMessage = null;
    },
    async changeMarket() {
      this.messagesOptions = [{ value: null, text: "Select a message" }];
      this.selectedMessage = null;
      await this.getMessages();
    },
    changeMessage() {
      this.refreshUrl();
    },
    replay() {
      if (this.multiformatsCheckboxStatus) {
        this.replayCreatives();
      } else {
        this.replayCreative();
      }
    },
    updateSelectedFormat() {
      this.currentFormat.size = this.formatOptions[this.selectedFormat].text;
      this.currentFormat.type = this.formats[this.selectedFormat].type;
      this.replay();
    },
    carouselPrev() {
      if (this.selectedFormat === 0) {
        this.selectedFormat = this.formats.length - 1;
      } else {
        this.selectedFormat--;
      }
      this.currentFormat.size = this.formatOptions[this.selectedFormat].text;
      this.currentFormat.type = this.formats[this.selectedFormat].type;
      this.replay();
    },
    carouselNext() {
      if (this.selectedFormat === this.formats.length - 1) {
        this.selectedFormat = 0;
      } else {
        this.selectedFormat++;
      }
      this.currentFormat.size = this.formatOptions[this.selectedFormat].text;
      this.currentFormat.type = this.formats[this.selectedFormat].type;
      this.replay();
    },

    refreshUrl() {
      let query = {};

      if (this.creativeSetId) query.creativeSetId = this.creativeSetId;
      if (this.selectedMarket) query.market = this.selectedMarket;
      if (this.selectedMessage) query.message = this.selectedMessage;
      query.token = this.token;

      this.$router.push({
        query,
      });
    },
    reloadIframe(formatName) {
      const format = this.formats.find((el) => el.name === formatName);
      format.updateUrl();
    },
    replayCreative() {
      const format = this.formats[this.selectedFormat];
      format.updateUrl();
    },
    replayCreatives() {
      this.$refs.creatives.forEach((element) => element.refresh());
    },
    async readCreativeSetSheet() {
      if (this.selectedCreativeSetId) {
        if (!this.marketDisabled) this.loadingMarket = true;
        this.loadingMessage = true;
        this.initDataMap = {};

        this.ctaSpreadsheet = await ExternalSpreadsheetService.getSheets(
          ["CTA_list", "CTAStyle_list"],
          SpreadsheetService.CTA_SPREADSHEET_ID,
          this.token
        );

        this.spreadsheet = await ExternalSpreadsheetService.getSheets(
          ["scenarios", "Assets_lang", "Assets_design"],
          this.selectedCreativeSet.spreadsheetId,
          this.token
        );

        console.log("this.spread", this.spreadsheet);
        try {
          if (!this.spreadsheet.scenarios.headers.includes(SpreadsheetService.SCENARIO_COL)) {
            throw Error("No column #scenario found.");
          }
          if (!this.spreadsheet.scenarios.headers.includes(SpreadsheetService.SIZE_COL)) {
            throw Error("No column #size found.");
          }

          if (!this.spreadsheet.scenarios.headers.includes(SpreadsheetService.INITDATA_COL)) {
            throw Error("No column json found.");
          }
        } catch (error) {
          ToastService.error("Error", `Error parsing spreadsheet headers :${ErrorUtils.getMessage(error)}`);
        }

        this.spreadsheet.scenarios.rows.forEach((row) => {
          const scenario = row[SpreadsheetService.SCENARIO_COL];
          const initDataT = row[SpreadsheetService.INITDATA_COL];
          const size = row[SpreadsheetService.SIZE_COL];
          const [market, message] = scenario.split("*");

          if (!this.marketOptions.find((element) => element.value === market)) {
            if (this.lockedMarket && market === this.lockedMarket) {
              this.marketOptions.push({
                value: market,
                text: market,
              });
            } else if (!this.lockedMarket) {
              this.marketOptions.push({
                value: market,
                text: market,
              });
            }
          }

          this.initDataMap[market + message + size] = {
            data: JSON.parse(initDataT),
            env: {
              name: "disneyland-cmp-banner-validation",
            },
          };
        });

        if (!this.marketDisabled) this.loadingMarket = false;
      }
    },

    async getMessages() {
      if (this.selectedCreativeSetId && this.selectedMarket) {
        this.loadingMessage = true;

        this.spreadsheet.scenarios.rows.forEach((row) => {
          const scenario = row[SpreadsheetService.SCENARIO_COL];
          const initDataT = row[SpreadsheetService.INITDATA_COL];
          const size = row[SpreadsheetService.SIZE_COL];
          const [market, message] = scenario.split("*");

          if (market === this.selectedMarket) {
            if (!this.messagesOptions.find((element) => element.value === message)) {
              this.messagesOptions.push({
                value: message,
                text: message,
              });
            }
          }

          this.initDataMap[market + message + size] = {
            data: JSON.parse(initDataT),
            env: {
              name: "disneyland-cmp-banner-validation",
            },
          };
        });

        this.loadingMessage = false;
      }
    },

    close() {
      this.replay();
    },
  },
  watch: {
    selectedMessage(message) {
      if (message) {
        this.currentFormat.size = this.formats[this.selectedFormat].name;
        this.currentFormat.type = this.formats[this.selectedFormat].type;
        this.getInitData(this.currentFormat.size);
        this.replay();
      }
    },
  },
  async mounted() {
    let resp = await CreativeSetService.readToken(this.$route.query.token);

    console.log("token full : ", resp);

    let query = {
      creativeSetId: resp.creativeSetId,
      market: resp.market,
      token: this.token,
    };

    if (this.$route.query.message) {
      query.message = this.$route.query.message;
    }

    this.$router.push({
      query,
    });

    if (resp.creativeSetId) {
      this.creativeSetDisabled = true;
    }

    if (resp.market) {
      this.marketOptions.push({ value: resp.market, text: resp.market });
      this.marketDisabled = true;
    }

    this.creativeSets = await CreativeSetService.listExternal(this.token);

    if (this.creativeSetId) {
      this.selectedCreativeSetId = this.creativeSetId;
      this.lockedCreativeSet = this.selectedCreativeSet;
    }

    this.creativeSets.forEach((el) => {
      this.creativeSetOptions.push({ value: el.id, text: el.name });
    });

    this.formats.forEach((format, index) => {
      this.formatOptions.push({ value: index, text: format.name });
    });

    await this.readCreativeSetSheet();

    if (this.$route.query.market) {
      this.selectedMarket = this.$route.query.market;
      this.lockedMarket = this.selectedMarket;
      await this.getMessages();
    }
    const urlMessageOption = this.messagesOptions.find((message) => {
      return message.text === this.$route.query.message;
    });

    if (urlMessageOption) {
      this.selectedMessage = urlMessageOption.value;
    }
  },
};
</script>
