import Vue from "vue";
import VuePageTitle from "vue-page-title";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./main.scss";
import vueFilePond from "vue-filepond";
import VueLogger from "vuejs-logger";
import axios from "axios";
import VueAxios from "vue-axios";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import BackEndpoints from "@/router/endpoints/BackEndpoints";

import AuthService from "@/services/AuthService";
import ToastService from "@/services/ToastService";
import LoadingService from "@/services/LoadingService";

Vue.config.productionTip = false;

// Configure vue-page-title
Vue.use(VuePageTitle, { router });

// Configure bootstrap-vue
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// Configure axios
axios.defaults.baseURL = BackEndpoints.backUrl;
Vue.use(VueAxios, axios);

// Configure logger
const isProduction = process.env.NODE_ENV === "production";
const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};
Vue.use(VueLogger, options);

// Configure FilePond
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);
Vue.component("FilePond", FilePond);

// Create vue app
const vm = new Vue({
  router,
  store,
  render: (h) => h(App),
});

// Configure services
AuthService.init();
ToastService.init(vm);
LoadingService.init(vm);

// Render vue app
vm.$mount("#app");
